import {
  Box,
  Button,
  ClickAwayListener,
  Popover,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

export default function DropDownbutton({
  title = "",
  sx = {},
  children,
  ...props
}) {
  const [dropdown, setDropdown] = useState(false);
  const dropDownRef = useRef();

  return (
    <Box>
      <Button
        onClick={() => setDropdown(true)}
        sx={{
          color: "inherit",
          bgcolor: "transparent",
          ...props,
          textTransform: "capitalize",
        }}
        ref={dropDownRef}
        endIcon={<IoMdArrowDropdown fontSize="1.16vw" />}
      >
        <Typography>{title}</Typography>
      </Button>
      <Popover
        open={dropdown}
        anchorEl={dropDownRef.current}
        sx={{ marginTop: 1 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          elevation: 0,
        }}
        onClose={() => setDropdown(false)}
      >
        <ClickAwayListener onClickAway={() => setDropdown(false)}>
          {children}
        </ClickAwayListener>
      </Popover>
    </Box>
  );
}
