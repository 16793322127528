import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

//images
import desktop from "../images/pricing/hero-desktop.png";
import mobile from "../images/pricing/hero-mobile.png";
import bounce from "../images/pricing/bounce-black.png";
// import ruler from '../images/pricing/ruler.png'
import carpet from "../images/pricing/carpet.png";
import hands from "../images/pricing/hands.png";
import handsMobile from "../images/pricing/hands.svg";
import priceBgImage from "../images/pricing/bg-image.png";
import undraw from "../images/pricing/undraw.png";
import cardPayment from "../images/pricing/card-payments.png";
import arrowBtn from "../images/pricing/arrowBtn.png";
import NavBar from "../component/layout/nav";
import TimelineDisplay from "../component/ui/timelineDisplay";
import GetStartedButton from "../component/ui/getStartedButton";

export function Btn({
  bgcolor,
  color,
  padding,
  borderRadius,
  mt,
  value,
  border,
  width,
}) {
  return (
    <Typography
      bgcolor={bgcolor}
      color={color}
      padding={padding}
      borderRadius={borderRadius}
      mt={mt}
      border={border}
      maxWidth={width}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {value}
      <img
        style={{ marginLeft: "10px", height: "22px" }}
        src={arrowBtn}
        alt="arrow btn"
      />
    </Typography>
  );
}

export function BasicTimeline(data) {
  return (
    <TimelineDisplay
      titleProps={{ variant: "h5", fontWeight: "500" }}
      rowGap="20px"
      data={data.data}
    />
  );
}

export default function Pricing() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  let bgImage = desktop;
  if (windowSize?.width > 700) {
    bgImage = desktop;
  } else {
    bgImage = mobile;
  }

  // console.log(windowSize, bgImage)

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    //This auto runs when there is no resize for current device width and height
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const data = [
    {
      title: "Dedicated QR Code",
      text: "Get specialized QR code to ease business transactions.",
    },
    {
      title: "Multiple Invoice ",
      text: "Create several unliteral invoices for your company's sales and transactions.",
    },
  ];

  const d2 = [
    {
      title: "Instant Notification",
      text: "Get notified as soon as a transaction occurs on your account.",
    },
    {
      title: "Virtual Account Numbers ",
      text: "Create and manage multiple virtual accounts to make it easier to conduct business.",
    },
  ];

  const d3 = [
    {
      title: "Customer Marketing Tools",
      text: "Gives your business the ability to market to potential customers.",
    },
    {
      title: "Sales Record",
      text: "A measurable sales record that provides business clarity and  profit margins.",
    },
  ];
  const allData = [...data, ...d2, ...d3];

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          minHeight: { xs: "470px", sm: "468px", md: "48.84vw" },
          // minHeight: {xs:"365px", sm:"582px", md:"60.76vw"},
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor:
            "linear-gradient(117.64deg, rgba(74, 70, 151, 0.52) 52.62%, rgba(50, 43, 176, 0.1196) 81.43%)",
          backgroundBlendMode: "darken",
        }}
      >
        <Box
          pl={{ xs: "24px", sm: "55px", md: "5.79vw" }}
          pr={{ xs: "24px", sm: "28px", md: "2.89vw" }}
        >
          <NavBar dark />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "flex-start", md: "center" },
            width: { xs: "100%", md: "50%" },
            m: "0 auto",
            mt: { xs: "47px", sm: "50px", md: "5.24vw" },
            px: { xs: "30px", md: "0" },
            pb: { xs: "30px", sm: 0 },
            // mb:{xs:"39px", sm:"165px", md:"17.19vw"},
            // mt: { xs: "120px", md: "140px" },
            // padding: "10px",
          }}
        >
          <Typography
            // variant="h2"
            color={"#FFFFFF"}
            textAlign={{ xs: "left", md: "center" }}
            fontSize={{ xs: "39px", md: "3.05vw" }}
            fontWeight={"500"}
            mb={{ xs: "24px", sm: "32px", md: "3.30vw" }}
          >
            WallX Offers a Pricing Plan That’s Adaptable to Your Business.
          </Typography>
          <GetStartedButton
            href="https://business.wallx.co/register"
            sx={{ px: { xs: "25px",md: "35px" }, maxWidth:{ sm:"38.00vw",md:"17.36vw"}}}
            // sx={{}}
          />
        </Box>

        <Box
          width="100%"
          maxWidth={{ xs: "176px", sm: "281px", md: "29.28vw" }}
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            position: "absolute",
            right: { xs: "39px", sm: "63px", md: "6.54vw" },
            // top: {xs:"226px", sm:"360px", md:"37.62vw"}
            bottom: { xs: "-42px", sm: "-67px", md: "-6.94vw" },
          }}
        >
          <img width={"100%"} src={bounce} alt="card" />
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: { xs: "50px", md: "4.40vw" },
          // margin: "0 auto",
        }}
        // width={{ xs: "100%", md: "90%" }}
        px={{ xs: "24px", sm: "55px", md: "5.79vw" }}
      >
        <Typography
          variant="h4"
          fontWeight={"500"}
          fontSize={{xs:"24px",md:"2.31vw"}}
        >
          What You Get
        </Typography>
        <Typography
          variant="body1"
          color={"#201E4B"}
          mt={{ xs: "4px", sm: "6px", md: "0.64vw" }}
          maxWidth={{ xs: "242px", sm: "312px", md: "32.58vw" }}
          fontSize={{ xs: "12px", sm: "13px", md: "1.39vw" }}
        >
          WallX simplifies access to a variety of financial and lifestyle
          services.
        </Typography>

        {/* For desktop */}
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          mt={{ xs: "21px", sm: "33px", md: "3.47vw" }}
        >
          <BasicTimeline data={data} />
          <BasicTimeline data={d2} />
          <BasicTimeline data={d3} />
        </Box>

        {/* For mobile */}
        <Box sx={{ display: { xs: "block", md: "none" } }} mt="22px">
          <BasicTimeline data={allData} />
        </Box>
      </Box>

      <Box
        mt={{ xs: "79px", md: "7.52vw" }}
        sx={{
          width: "100%",
          // pl: { xs: "24px", md: "0" },
          display: { xs: "block", md: "flex" },
          alignItems: "center",
          // flexWrap: {xs: "wrap"}
        }}
      >
        <Box
          sx={{
            background: {
              xs: "#FFFFFF",
              md: "linear-gradient(107.33deg, #443792 -1.88%, rgba(142, 81, 221, 0.93) 100.64%)",
            },
            maxWidth: { md: "49.54vw" },
            width:"100%",
            height: { md: "41.44vw" },
            // padding: { xs: "20px", md: "70px" },
            pl: { xs: "24px", md: "5.79vw" },
            pr: { xs: "24px", md: "9.09vw" },
            pt: { xs: "0", md: "5.79vw" },
            pb: { xs: "24px", md: "17.48vw" },

            // zIndex: 1,
            // position: "relative",
            // left: "20px"
          }}
        >
          <Typography
            // variant="h2"
            fontSize={{xs:"1.25em", md:"2.89vw"}}
            fontWeight={500}
            maxWidth={{xs:"73.60vw", sm:"34.38vw"}}
            color={{ xs: "#000000", md: "#FFFFFF" }}
            textAlign={"left"}
          >
            Payment Platform To  Share, Spend with Ease
          </Typography>
          <Typography
            variant="subtitle1"
            color={{ xs: "#000000", md: "#FFFFFF" }}
            textAlign={"left"}
            mt={1.5}
            mb={1.5}
            // fontSize={{xs:"4.00vw", ta:"1.39vw"}}
            maxWidth={{ xs: "284px", sm: "384px", md: "100%" }}
          >
            Get a fully functional financial application that offers payment and
            business services.
          </Typography>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {/* <Btn
                            bgcolor={"#FFFFFF"}
                            borderRadius={"20px"}
                            color={"#443792"}
                            padding={"12px 20px"}
                            value={"Get Started"}
                            // display={"none"}
                            visibility={"inherit"}
                            width={"150px"}
                        /> */}
            <GetStartedButton
              href="https://business.wallx.co/register"
              sx={{ px: "35px" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: { md: "46.99vw" },
            maxWidth: { md: "53.65vw" },
            //   minHeight: { xs: "282px", sm: "450px", md: "80.99vh" },
            backgroundImage: { md: `url(${carpet})` },
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundBlendMode: "darken",
            //   marginTop: { xs: "30px", md: "-40px" },
            // width: { xs: "100%", md: "50%" }
          }}
        >
          <Box
            sx={{
              width: "100%",
              mt: { xs: "24px", md: "5.32vw" },
              pr: { xs: "24px", md: 0 },
              maxWidth: { md: "56.89vw" },
              // height: { xs: "98%", sm: "98%", md: "80%" },
              overflow: "hidden",
              position: "relative",
              //   top: { xs: "-35px", md: "55px" },
            }}
          >
            <img
              src={isTablet ? handsMobile : hands}
              style={{ width: "100%", height: "100%" }}
              alt="holding hands"
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          //   height: { xs: "fit-content", sm: "60vh", md: "106.30vh" },
          backgroundImage: { xs: "", md: `url(${priceBgImage})` },
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          //   padding: "10px",
          pt: { xs: "9px", sm: "14px", md: "1.45vw" },
          marginTop: { xs: "58px", md: "4.28vw" },
          //   marginBottom: { xs: "77px", md: "0px" },
          //   overflow: "hidden",
        }}
      >
        <Box postion="absolute">
          <Typography
            variant="h2"
            fontWeight={500}
            fontSize={{xs:"1.25em", md:"2.89vw"}}
            textAlign={"center"}
            m={3}
          >
            Pricing Plan
          </Typography>

          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "70%" },
              // maxWidth: "38vw",
              margin: "0 auto",
              gap: "30px",
              display: { md: "flex" },
              justifyContent: "space-evenly",
              //   padding: "10px",
            }}
          >
            <Box
              position="relative"
              mb={{ xs: "38px", sm: "60px", md: "6.01vw" }}
              pb={{xs:"39px", sm:"50px", md:"0"}}
              px={{xs:"41px", sm:"65px", md:"6.83vw"}}
              pt={{xs:"39px", sm:"50px", md:"5.27vw"}}
              sx={{
                background:
                  "linear-gradient(107.33deg, #4640B9 -1.88%, rgba(162, 124, 210, 0.88) 100.64%)",
                borderRadius: { xs: "15px", md: "0.87vw" },
                minHeight: { md:"39.00vw"},
                // display: { xs: "flex", md: "block" },
                justifyContent: "center",
                alignItems: "center",
                minWidth: { xs: "100%", sm: "100%", md: "30.85vw" },
              }}
            >
              <Typography
                variant="body1"
                fontWeight={500}
                fontSize={{xs:"19px",md:"1.74vw"}}
                textAlign={"center  "}
                color={"#FFFFFF"}
              >
                0.37% per transaction <br /> capped at
              </Typography>

              <Typography
                variant="body1"
                fontWeight={700}
                fontSize={{ xs: "24px", sm: "39px", md: "4.05vw" }}
                textAlign={"center  "}
                color={"#FFFFFF"}
                mt={{xs:"10px", sm:"32px", md:"3.30vw"}}
              >
                ₦1,500
              </Typography>

              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    overflow: "hidden",
                  },
                //   padding: "20px",
                  position: "absolute",
                  width:"100%",
                  left:{xs:"-11px", sm:"-18px", md:"-1.91vw"},
                  bottom: { xs: "38px", sm: "60px", md: "-6.31vw" },
                  //   top: { sm: "-133px", md: "-13.89vw" },
                  //   left: { xs: "111px", sm: "177px", md: "18.52vw" },
                  maxWidth: {xs:"184px", sm:"294px", md:"30.73vw"},
                  //   height: { xs: "104px", sm: "166px", md: "17.36vw" },
                  zIndex: 1,
                }}
              >
                <img
                  src={undraw}
                  style={{ height: "100%", width: "100%" }}
                  alt="illustrator"
                />
              </Box>
            </Box>

            <Box
              position="relative"
              mb={{ xs: "38px", sm: "60px", md: "6.01vw" }}
              pb={{xs:"39px", sm:"50px", md:"0"}}
              px={{xs:"41px", sm:"65px", md:"6.83vw"}}
              pt={{xs:"39px", sm:"50px", md:"5.27vw"}}
              sx={{
                background:
                  "linear-gradient(107.33deg, #4640B9 -1.88%, rgba(162, 124, 210, 0.88) 100.64%)",
                  borderRadius: { xs: "15px", md: "0.87vw" },
                  minHeight: { md:"39.00vw"},
                // display: { xs: "flex", md: "block" },
                justifyContent: "center",
                alignItems: "center",
                minWidth: { xs: "100%", sm: "100%", md: "30.85vw" },
              }}
            >
              <Typography
                variant="body1"
                fontWeight={500}
                fontSize={{xs:"19px",md:"1.74vw"}}
                textAlign={"center"}
                color={"#FFFFFF"}
              >
                Monthly retainer <br /> per store at
              </Typography>

              <Typography
                variant="body1"
                fontWeight={700}
                fontSize={{ xs: "24px", sm: "39px", md: "4.05vw" }}
                textAlign={"center  "}
                color={"#FFFFFF"}
                mt={{xs:"10px", sm:"32px", md:"3.30vw"}}

              >
                ₦4,500
              </Typography>
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                },
                position: "absolute",
                bottom: { xs: "38px", sm: "60px", md: "-6.31vw" },
                right:{xs:"-38px", sm:"-60px", md:"-6.25vw"},
                width:"100%",

                //   top: { sm: "-297px", md: "-30.96vw" },
                //   left: { xs: "309px", sm: "493px", md: "51.50vw" },
                maxWidth: {xs:"184px", sm:"294px", md:"30.73vw"},
                //   height: { xs: "104px", sm: "166px", md: "17.36vw" },
                zIndex: 1,
              }}
            >
              <img
                src={cardPayment}
                style={{ height: "100%", width: "100%" }}
                alt="illustrator"
              />
            </Box>
            </Box>

          </Box>

          <Box></Box>
        </Box>
      </Box>
    </Box>
  );
}
