import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Typography, Box } from "@mui/material";

import dotDark from "../../images/general/dotDark.svg";
import dotLight from "../../images/pricing/dot.png";

export default function TimelineDisplay({
  data,
  titleProps = {},
  textProps = {},
  iconProps = {},
  rowGap = {},
  light,
  ...props
}) {
  data = data || [];

  return (
    <Timeline sx={{ p: 0 }} {...props}>
      {data.map((item, index) => (
        <TimelineItem key={item.title} index={item.title}>
          <TimelineOppositeContent sx={{ flex: "0", p: 0, pr: 0 }} />
          <TimelineSeparator>
            <TimelineDot
              sx={{ boxShadow: "none", p: 0, bgcolor: "transparent" }}
            >
              <Box width={{ xs: "14px", md: "1.50vw" }} height={{ xs: "14px", md: "1.50vw" }}>
                <img
                  src={light ? dotLight : dotDark}
                  width="100%"
                  height={"100%"}
                  alt="dotdarkicon"
                />
              </Box>
            </TimelineDot>
            {index < data.length - 1 && (
              <TimelineConnector
                sx={{ width: { xs: "1px", sm: "1px", md: "0.12vw" } }}
              />
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Typography {...titleProps}>
              {item.title || `Step ${index + 1}`}
            </Typography>
            <Typography pb={rowGap} {...textProps}>
              {item.text}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
