import React, { useState, useEffect } from "react";
import { Avatar, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FaUsers } from "react-icons/fa";
import AppTheme from "../../style/theme/main";

import moneyIcon from "../../images/home/moneyIcon.svg";

import lifeImg1 from "../../images/home/lifeImg1.svg";
import lifeImg2 from "../../images/home/lifeImg2.svg";
import lifeImg21 from "../../images/home/lifeImg21.svg";
import lifeImg22 from "../../images/home/lifeImg22.svg";
import lifeImg31 from "../../images/home/lifeImg31.svg";
import lifeImg32 from "../../images/home/lifeImg32.svg";

import walletIcon from "../../images/home/walletIcon.svg";

import lifystyleBg from "../../images/home/lifystyleBg.svg";

export default function LifestyleComponent() {
  const [lifeStyle, setLifeStyle] = useState("first");

  const [imgSlide, setImgSlide] = useState(null);
  // const [imgRoll, setImgRoll] = useState("180px")

  const imgChange = (lifeStyle) => {
    if (lifeStyle === "first") {
      setImgSlide([lifeImg1, lifeImg2]);
      // setImgRoll("180px")
    } else if (lifeStyle === "second") {
      setImgSlide([lifeImg21, lifeImg22]);
      // setImgRoll("340px")
    } else if (lifeStyle === "third") {
      setImgSlide([lifeImg31, lifeImg32]);
      // setImgRoll("460px")
    }
  };

  useEffect(() => {
    imgChange(lifeStyle);
  }, [lifeStyle]);

  // switch (lifeStyle) {
  //   case "first":
  //     setImgSlide([lifeImg1, lifeImg2])
  //     break;
  //   case "second":
  //     setImgSlide([lifeImg1, lifeImg2])
  //     break;
  //   case "third":
  //     setImgSlide([lifeImg1, lifeImg2])
  //     break;
  //   default:
  //     break;
  // }

  const handleFirstText = () => {
    setLifeStyle("first");
  };
  const handleSecondText = () => {
    setLifeStyle("second");
  };
  const handleThirdText = () => {
    setLifeStyle("third");
  };
  return (
    <Grid
      container
      sx={{ paddingLeft: { xs: "10px", sm: "70px", md: "70px" } }}
      columnSpacing={{xs:"25px", sm:"40px", md:"4.17vw"}}
    >
      <Grid item xs={12} sm={6} md={4}>
        <Box>
          <Typography variant="subtitle1">WallX Lifestyle</Typography>
          <Typography variant="h4" fontWeight="600">
            Enjoy Other Lifestyle Services
          </Typography>
        </Box>
        <Stack
          // mt={"35px"}
          mt={{ xs: "22px", sm: "35px", md: "49px" }}
          // spacing="35px"
          rowGap="35px"
          mr={{xs:"25px", sm:"40px", md:"4.17vw"}}
          // width={{ xs: "100%", md: "60%" }}
          display="flex"
        >
          {/* <Box
            sx={{
              width: "6%",
              position: "relative",
              top: imgRoll,
              transition: "1s",
            }}
          >
            <img src={lifystyleBg} alt="slider" />
          </Box> */}
          <Box
            onMouseEnter={handleFirstText}
            onClick={handleFirstText}
            sx={{
              background: {
                xs: "",
                md:
                  lifeStyle === "first"
                    ? `url('${lifystyleBg}') no-repeat`
                    : "",
              },
              // backgroundPosition: "0px -25px",
              transition: "1s",
              pl: { xs: "0px", sm: "20px" },
              ml: { xs: "0px", sm: "-20px" },
              backgroundRepeat: "no-repeat",
            }}
          >
            <Avatar
              src={moneyIcon}
              variant="square"
              sx={{
                width: { xs: "24px", md: "1.74vw" },
                height: { xs: "24px", md: "1.74vw" },
              }}
            />
            <Typography my={"8px"} fontWeight="600" variant="h5">
              Bill Payment
            </Typography>
            <Typography  variant="body1">
              {/* Get on WallX to perform socio-lifestyle transactions with ease.
              Suitable for businesses and individuals. */}
              WallX enables you to pay your Electricity bills, Cable Tv, Buy
              airtime and Subscribe for data from your Smartphone or PC.
            </Typography>
          </Box>
          <Box
            // pl={"20px"}
            onMouseEnter={handleSecondText}
            sx={{
              background: {
                xs: "",
                md:
                  lifeStyle === "second"
                    ? `url('${lifystyleBg}')  no-repeat`
                    : "",
              },
              backgroundPosition: "0px 25px",
              transition: "1s",
              pl: { xs: "0px", sm: "20px" },
              ml: { xs: "0px", sm: "-20px" },
              backgroundRepeat: "no-repeat",
            }}
            onClick={handleSecondText}
          >
            <Box fontSize={{ xs: "24px", md: "1.74vw" }}>
            <FaUsers
              // style={{ width: "20px", height: "20px" }}
              fontSize="inherit"
              color={AppTheme.palette.primary.main}
            />

            </Box>
            <Typography my={"8px"} fontWeight="600" variant="h5">
              Monetary Contribution
            </Typography>
            <Typography>
              With wallX contribution, you can perform social lifestyle
              contributions such as Bill sharing, Ajo contribution and donation
              based crowdfunding.
            </Typography>
          </Box>
          <Box
            // pl={"20px"}
            sx={{
              background: {
                xs: "",
                md:
                  lifeStyle === "third"
                    ? `url('${lifystyleBg}')  no-repeat`
                    : "",
              },
              backgroundPosition: "0px 25px",
              transition: "1s",
              pl: { xs: "0px", sm: "20px" },
              ml: { xs: "0px", sm: "-20px" },
              backgroundRepeat: "no-repeat",
            }}
            onMouseEnter={handleThirdText}
            onClick={handleThirdText}
          >
            <Avatar
              src={walletIcon}
              variant="square"
              sx={{
                width: { xs: "24px", md: "1.74vw" },
                height: { xs: "24px", md: "1.74vw" },
              }}
            />
            <Typography my={"8px"} fontWeight="600" variant="h5">
              Savings Wallet
            </Typography>
            <Typography>
              Enjoy attractive interest by saving on WallX
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid
        bgcolor={"#FBF5FE"}
        item
        xs={12}
        sm={6}
        md={8}
        pt={"75px"}
        px={"35px"}
        sx={{ display: { xs: "none", md: "initial" } }}
      >
        <Stack direction="row">
          <Box
            width="100%"
            maxWidth={{ xs: "212px", sm: "338px", md: "35.30vw" }}
          >
            <img width="100%" alt="" src={imgSlide?.[0]} />
          </Box>
          <Box
            maxWidth={{ xs: "123px", sm: "197px", md: "20.54vw" }}
            mt={{ xs: "73px", sm: "117px", md: "165px" }}
            width="100%"
            ml={{ xs: "-34px", sm: "-54px", md: "-77px" }}
          >
            <img
              height="100%"
              width="100%"
              // style={{ maxWidth: "400px" }}
              alt=""
              src={imgSlide?.[1]}
            />
          </Box>
        </Stack>
        {/* <Grid item xs={7} zIndex="1">
          <img width="100%" alt="" src={imgSlide?.[0]} />
        </Grid>
        <Grid
          item
          xs={10}
          mt={"-300px"}
          justifySelf="flex-end"
          textAlign={"right"}
        >
          <img
            height="100%"
            width="100%"
            style={{ maxWidth: "400px" }}
            alt=""
            src={imgSlide?.[1]}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
}
