import { Box, Typography } from "@mui/material"

export default function MerchantSlideCard(props) {
  return (
    <Box
      style={{
        background:
          "linear-gradient(107.33deg, #4640B9 -1.88%, rgba(162, 124, 210, 0.88) 100.64%)",
      }}
      py={{xs:"17px", sm:"28px", md:"2.89vw"}}
      px={{xs:"14px", sm:"11px", md:"1.16vw"}}
      height="100%"
      pb={{xs:"14px"}}
      minHeight={{xs:"81px", sm:"129px", md:"13.43vw"}}
      maxWidth={{sm:"310px", md:"32.35vw"}}
    >
      <Box color="white">
        <Typography fontSize={{xs:"18px",md:"1.74vw"}} fontWeight={"500"} variant="h5">
          {props.title}
        </Typography>
        <Typography
          fontWeight="400"
          variant="body1"
          mt={{xs:"3px", sm:"6px", md:"0.58vw"}}
        >
          {props.text}
        </Typography>
      </Box>
    </Box>
  )
}
