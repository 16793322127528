import { styled, Button } from "@mui/material";


const RoundButton = styled(Button)(({ variant }) => {
  const style = {
    borderRadius: "30px",
    boxShadow: "none",
  }
  return style
})


export default RoundButton