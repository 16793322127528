import {
  Box,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  // Grid,
} from "@mui/material";
import NavBar from "../../component/layout/nav";

import { Slide, Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { IoMdWallet } from "react-icons/io";
import { FaUserCircle, FaMoneyBill } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";

import heroBg from "../../images/services/pos/hero.svg";
import heroPhone from "../../images/services/pos/heroPhone.svg";
import customerImage from "../../images/services/pos/customerImage.png";
import customerBg from "../../images/services/pos/customerBg.png";
import agentImage from "../../images/services/pos/agent.svg";
import agentBg from "../../images/services/pos/agentBg.png";
// import topUpBg from "../../images/services/pos/topupBg.svg"
import accountImage from "../../images/services/pos/accountDashboard.svg";
import stepGuideImage02 from "../../images/services/pos/stepGuide02.svg";
import stepGuideImage03 from "../../images/services/pos/stepGuide03.svg";
import stepGuideImage04 from "../../images/services/pos/stepGuide04.svg";
import stepGuideImage05 from "../../images/services/pos/stepGuide05.svg";
import stepGuideImage06 from "../../images/services/pos/stepGuide06.svg";

import safeLockIcon from "../../images/services/pos/safeLockIcon.svg";
import paymentIcon from "../../images/services/pos/paymentIcon.svg";
import transferIcon from "../../images/services/pos/safeLockIcon.png";
import ePinIcon from "../../images/services/pos/ePinIcon.png";

import SlideCard from "../../component/ui/services/pos/slidCard";
import TimelineDisplay from "../../component/ui/timelineDisplay";
import GetStatedButton from "../../component/ui/getStartedButton";

import afterBg from "../../images/services/pos/after.svg";
import beforeBg from "../../images/services/pos/beforeBg.svg";
import GetStartedButton from "../../component/ui/getStartedButton";
// import afterBg from "../../images/services/pos/afterBG.png"
// import beforeBg from "../../images/services/pos/beforBg.png"

// import "../../style/css/service/pos.css"

const accentColor = "#FCF8FF";

const customerTasks = [
  { title: "Generate", text: "Pin Payment" },
  { title: "Enter Customer", text: "Receiver Details", dark: true, ml: true },
  { title: "Enter", text: "Secret Code", dark: true },
  { title: "Select ", text: "Payment Channel", mr: true },
];

const agentGuide = [
  { text: "Register on WallX Vend", icon: RiLogoutBoxRLine },
  { text: "Open an account ", icon: FaUserCircle },
  { text: "Finance your wallet  ", icon: IoMdWallet },
  { text: " Start executing financial transactions.", icon: FaMoneyBill },
];

const posServices = [
  {
    title: "Withdraw/Transfer",
    text: "You can send, transfer, withdraw, and receive money for your clients as a WallX Agent while also charging them a commission.",
    image: transferIcon,
  },
  {
    title: "ePin Withdrawal",
    text: "With WallX POS, you can make a cash withdrawal from a nearby Agent without a savings account, ATM card, or smartphone. Pick up your money using only your PIN",
    image: ePinIcon,
  },
  {
    title: "Safe-Lock",
    text: "Wallx POS allows you to lock down some funds for a limited period to grow your business as an agent and earn a commission upfront.",
    image: safeLockIcon,
  },
  {
    title: "Resell Bill Payments",
    text: "Become a WallX Agent and earn a commission by selling subscriptions such as airtime, data, WAEC pins, DSTV, GOTV, and electricity.",
    image: paymentIcon,
  },
];

const stepGuide = [
  {
    title: "How to Top Up WallX Account",
    text: "You can make a direct transfer to your wallX account number from any bank or using a the form.",
    image: accountImage,
    dark: true,
    steps: [
      { text: "On Login, Click on Topup" },
      { text: "Enter Amount" },
      { text: "Select Payment Method" },
      { text: "Select Payment Channel" },
      { text: "Submit Request" },
    ],
  },
  {
    title: "How to Process a Wallet to Bank Withdrawal",
    text: "",
    image: stepGuideImage02,
    steps: [
      { text: "On Login, Click on Withdraw" },
      { text: "Select Source Wallet" },
      { text: "Enter Account Details" },
      { text: "Select Amount" },
      { text: "Enter Wallet Pin" },
    ],
  },
  {
    title: "How to Process a Transfer",
    text: "",
    image: stepGuideImage03,
    steps: [
      { text: "On Login, Click on Transfer" },
      { text: "Select Source Wallet" },
      { text: "Enter Beneficiary" },
      { text: "Select Destination Bank" },
      { text: "Enter Account Details" },
      { text: "Enter Amount" },
      { text: "Include Narration " },
      { text: "Enter Wallet Pin " },
    ],
  },
  {
    title: "How to Safelock Funds",
    text: "Lock your fund away for a desired period of time and earn interest on monthly",
    image: stepGuideImage04,
    steps: [
      { text: "On Login, Click on Safelock" },
      { text: "Enter Amount" },
      { text: "Select Lock Period" },
      { text: "Select Interest Type" },
      { text: "Select Payment Method" },
      { text: "Enter Wallet Pin" },
    ],
  },
  {
    title: "How to Pay Bills",
    text: "",
    image: stepGuideImage05,
    steps: [
      { text: "On Login, Click on Bill Payment" },
      { text: "Select Bill Option" },
      { text: "Select Provider Option" },
      { text: "Fill Request Form" },
      { text: "Submit Request" },
    ],
  },
  {
    title: "How to Accept Pin Payment",
    text: "",
    image: stepGuideImage06,
    steps: [
      { text: "On Login, Click on Cardless Withdrawal" },
      { text: "Click on Accept Payment " },
      { text: "Enter Pin and Security Word" },
      { text: "Verify Request" },
    ],
  },
];

export default function ServicePosPage() {
  const theme = useTheme();
  const activeIndex = 0;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        color="white"
        px={{ xs: "23px", sm: "0" }}
        pb={{ xs: "66px", sm: "auto" }}
        sx={{
          background: {
            xs: "linear-gradient(107.47deg, rgba(79, 55, 146, 0.7298) -4.29%, rgba(124, 61, 206, 0.89) 121.97%)",
            sm: `url(${heroBg})`,
          },
          backgroundSize: { sm: "cover" },
          backgroundRepeat: { sm: "no-repeat" },
        }}
        minHeight={{ xs: "30px", sm: "47px", md: "4.92vw" }}
      >
        <Box
          pl={{ xs: "0px", sm: "55px", md: "5.79vw" }}
          pr={{ xs: "0px", sm: "27px", md: "2.78vw" }}
          position={"relative"}
        >
          <NavBar dark />
        </Box>
        <Stack
          mt={{ xs: "19px", sm: "31px", md: "3.24vw" }}
          direction="row"
          justifyContent={"space-between"}
          pl={{ xs: "0px", sm: "55px", md: "5.79vw" }}
        >
          <Box
            width="100%"
            maxWidth={{ xs: "88.60vw", sm: "477px", md: "49.77vw" }}
            mb={{ xs: "69px", sm: "110px", md: "11.46vw" }}
          >
            <Typography
              maxWidth={{ xs: "86.60vw", sm: "100%" }}
              variant="h1"
              fontWeight="400"
            >
              Provide your Customers with Quick and Simple Financial Services.
            </Typography>
            <Typography
              variant="h5"
              fontWeight="400"
              sx={{ mt: { xs: "7px", sm: "11px", md: "1.16vw" } }}
            >
              A retail service that offers incredible discounts and commissions
              for individuals, and small and medium-sized businesses. Sign up
              and begin to earn!
            </Typography>
            <GetStatedButton
              href="https://wallxvend.com/"
              sx={{ mt: { xs: "23px", md: "1.74vw" } }}
            />
          </Box>

          <Box
            width="100%"
            maxWidth={{ xs: "221px", sm: "353px", md: "36.86vw" }}
            // mr={{ xs: "22px", sm: "35px", md: "49px" }}
            mr={{ xs: "22px", sm: "35px", md: "3.70vw" }}
            // mb={{ xs: "4px", sm: "7px", md: "9px" }}
            display={{ xs: "none", md: "block" }}
            mt={{ xs: "22px", sm: "35px", md: "3.70vw" }}
          >
            <img src={heroPhone} width="100%" alt="hero_phone" />
          </Box>
        </Stack>
      </Box>

      <Stack
        mt={{ xs: "35px", sm: "55px", md: "5.79vw" }}
        px={{ xs: "23px", md: "0" }}
        textAlign="center"
        rowGap={{ xs: "7px", sm: "11px", md: "1.16vw" }}
        alignItems="center"
      >
        <Typography variant="subtitle1" fontWeight={"300"}>
          We Offer
        </Typography>
        <Typography
          fontWeight={"500"}
          variant="h4"
          // mt={{ xs: "7px", sm: "11px", md: "15px" }}
          maxWidth={{ xs: "84.00vw", sm: "383px", md: "39.93vw" }}
        >
          A Point Of Sale Service For Small And Medium Size Businesses.
        </Typography>
        <Typography
          variant="body1"
          // mt={{ xs: "7px", sm: "11px", md: "15px" }}
          maxWidth={{ xs: "79.00vw", sm: "337px", md: "35.19vw" }}
        >
          Sell from anywhere as a Point of Sale Agent on WallX Vend and earn
          instant commissions.
        </Typography>
      </Stack>

      <Stack
        className="slide-container"
        // direction={"row"}
        mt={{ xs: "18px", sm: "28px", md: "2.95vw" }}
        columnGap={{ xs: "17px", sm: "27px", md: "39px" }}
      >
        <Slide arrows={false} duration={2000} slidesToShow={isMobile ? 1 : 2}>
          {posServices.map((item, index) => (
            <Stack
              className="each-slide"
              height="100%"
              key={item.title}
              mx={{ xs: "17px", sm: "28px", md: "2.89vw" }}
            >
              <SlideCard {...item} key={`slide_${index}`} />
            </Stack>
          ))}
        </Slide>
      </Stack>

      <Stack
        justifyContent="space-between"
        mt={{ xs: "20.00vw", sm: "54px", md: "5.67vw" }}
        flexDirection={{ xs: "column-reverse", sm: "row" }}
        // columnGap={{xs:"24px", sm:"38px", md:"3.94vw"}}
        alignItems="center"
      >
        <Box
          width="100%"
          maxWidth={{ xs: "257px", sm: "411px", md: "42.88vw" }}
          pl={{ xs: "17px", sm: "27px", md: "39px" }}
          mt={{ xs: "10.44vw", sm: 0 }}
        >
          <img src={customerImage} alt="cutomer_image " width="100%" />
        </Box>
        <Box
          // mt={{ xs: "23px", sm: "37px", md: "53px" }}
          px={{ xs: "23px", sm: "0" }}
          maxWidth={{ sm: "327px", md: "34.14vw" }}
        >
          <Typography variant="subtitle1" fontWeight={"300"}>
            POS Services for customers
          </Typography>
          <Typography variant="h4" mt={{ xs: "3px", sm: "5px", md: "8px" }}>
            Instantaneously send and receive money to any WallX agent or bank
            account.
          </Typography>
          <Stack
            mt={{ xs: "13px", sm: "21px", md: "29px" }}
            direction={"row"}
            display={{ xs: "none", sm: "flex" }}
            flexWrap="wrap"
            // rowGap={{ xs: "10px", sm: "16px", md: "23px" }}
            columnGap={{ xs: "7px", sm: "11px", md: "16px" }}
            // pr={{ xs: "41px", sm: "65px", md: "92px" }}
          >
            {customerTasks.map((item, index) => {
              let mt =
                index % 2 !== 0 ? { xs: "7px", sm: "11px", md: "15px" } : "0";
              let bgcolor = item.dark ? theme.palette.primary.main : "#FCF8FF";
              let color = !item.dark ? theme.palette.primary.main : "white";
              let sx = { mt, bgcolor, color };
              return (
                <Stack
                  key={`task_${index}`}
                  borderRadius={"0.58vw"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={sx}
                  py={{ xs: "21px", sm: "33px", md: "47px" }}
                  px={{ xs: "9px", sm: "14px", md: "19px" }}
                  width={{ xs: "73px", sm: "116px", md: "12.15vw" }}
                  height={{ xs: "63px", sm: "100px", md: "10.47vw" }}
                >
                  <Typography variant="body2" fontWeight="500">
                    {item.title} <br /> {item.text}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Box>
        <Box
          width="100%"
          display={{ xs: "none", md: "block" }}
          maxWidth={{ xs: "65px", sm: "103px", md: "10.76vw" }}
          height={{ xs: "206px", sm: "329px", md: "34.38vw" }}
        >
          <img
            src={customerBg}
            alt="cutomer_background_image"
            height={"100%"}
            width="100%"
          />
        </Box>
      </Stack>

      <Stack
        columnGap={{ xs: "32px", sm: "51px", md: "5.32vw" }}
        direction={{ xs: "column", sm: "row" }}
        // justifyContent="space-between"
        mt={{ xs: "65px", sm: "104px", md: "10.88vw" }}
        mb={{ xs: "79px", md: "0.00vw" }}
        pl={{ sm: "44px", md: "4.63vw" }}
        px={{ xs: "23px", sm: "0" }}
        alignItems={{ sm: "center" }}
      >
        <Box mb="61px" display={{ sm: "none" }}>
          <Typography fontSize="13px" fontWeight="300">
            How can I become a WallX POS agent?{" "}
          </Typography>
          <Typography
            fontSize={{ xs: "19px" }}
            mb="23px"
            fontWeight="500"
            mt="8px"
          >
            With WallX, being an agent is now simple.
          </Typography>
          <GetStartedButton variant="outlined" />
        </Box>

        <Box
          width="100%"
          ml={{ sm: "6.67vw", md: "0" }}
          // mb={{ xs: "22.22vw", ta: "6.77vw", md: 0 }}
          maxWidth={{ sm: "82.67vw", md: "35.42vw" }}
        >
          <img src={agentImage} alt="pos_agent_image " width="100%" />
        </Box>

        <Box
          width="100%"
          pt={{ xs: "17px", sm: "28px", md: "2.89vw" }}
          display={{ xs: "none", ta: "block" }}
          // maxWidth={{ xs: "291px", sm: "465px", md: "660px" }}
          sx={{
            backgroundImage: `url(${agentBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "80%",
            backgroundPosition: "top right",
          }}
        >
          <Typography variant="subtitle1" fontWeight={"300"}>
            {" "}
            How can I become a WallX POS agent?{" "}
          </Typography>
          <Typography variant="h4" fontWeight={"500"}>
            {" "}
            With WallX, being an agent is now simple.
          </Typography>

          <Stack
            mt={{ xs: "6px", sm: "9px", md: "0.98vw" }}
            pr={{ xs: "39px", sm: "63px", md: "6.54vw" }}
            // maxWidth={{ xs: "283px", sm: "452px", md: "640px" }}
            rowGap={{ xs: "10px", sm: "17px", md: "1.74vw" }}
          >
            {agentGuide.map((item, index) => (
              <Stack
                bgcolor={
                  index === activeIndex
                    ? theme.palette.primary.main
                    : accentColor
                }
                color={
                  index === activeIndex ? "white" : theme.palette.primary.main
                }
                borderRadius="0.58vw"
                px={{ xs: "22px", sm: "34px", md: "3.59vw" }}
                py={{ xs: "11px", sm: "17px", md: "1.79vw" }}
                columnGap={{ xs: "10px", sm: "17px", md: "1.74vw" }}
                key={`agent_${index}`}
                flexDirection={"row"}
                fontSize={{ xs: "14px", sm: "22px", md: "2.31vw" }}
              >
                <item.icon color="inerit" fontSize="inherit" />
                <Typography variant="h5" fontWeight="400">
                  {item.text}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Stack>

      <Box
        display={{ xs: "none", md: "block" }}
        mt={{ xs: "41px", sm: "65px", md: "6.77vw" }}
        // bgcolor={accentColor}
        mb={{ xs: "33px", sm: "52px", md: "5.44vw" }}
      >
        <Zoom
          arrows={false}
          duration={18000}
          pauseOnHover={false}
          slidesToShow={1}
        >
          {stepGuide.map((item, index) => (
            <Box
              color={"white"}
              minHeight={{ sm: "677px", md: "70.72vw" }}
              position="relative"
              sx={{
                background:
                  "linear-gradient(107.33deg, #4640B9 -1.88%, rgba(162, 124, 210, 0.88) 100.64%)",
              }}
            >
              <Box
                width="100%"
                height={{ xs: "21px", sm: "33px", md: "3.47vw" }}
                position="absolute"
                top="0"
              >
                <img
                  src={beforeBg}
                  style={{ marginTop: "-3px" }}
                  width="100%"
                  alt="curve_before_image"
                />
              </Box>
              <Box
                pt={{ xs: "56px", sm: "89px", md: "9.26vw" }}
                pl={{ xs: "38px", sm: "60px", md: "6.31vw" }}
              >
                <Typography
                  variant="h4"
                  mt={{ xs: "35px", sm: "55px", md: "5.79vw" }}
                  mb={{ xs: "10px", sm: "17px", md: "1.74vw" }}
                >
                  {item.title}
                  {/* How to Top Up WallX Account */}
                </Typography>
                <Stack
                  flexDirection={"row"}
                  justifyContent="space-between"
                  // columnGap={{ xs: "45px", sm: "71px", md: "101px" }}
                >
                  <Box maxWidth={{ xs: "181px", sm: "289px", md: "30.21vw" }}>
                    <TimelineDisplay
                      data={item.steps}
                      light
                      iconProps={{ color: "white" }}
                      titleProps={{ variant: "body1" }}
                      rowGap={{ xs: "15px", sm: "24px", md: "2.55vw" }}
                      textProps={{ variant: "h5", fontWeight: "500" }}
                    />
                  </Box>

                  <Box
                    width="100%"
                    maxWidth={{ xs: "352px", sm: "562px", md: "58.68vw" }}
                  >
                    <Box
                      // mt={{ xs: "13px", sm: "20px", md: "29px" }}
                      maxWidth={{ xs: "166px", sm: "264px", md: "27.60vw" }}
                    >
                      <Typography variant="body2">
                        {item.text}
                        {/* You can make a direct transfer to your wallX account
                      number from any bank or using a the form. */}
                      </Typography>
                    </Box>
                    <Box
                      width="100%"
                      mt={{ xs: "14px", sm: "22px", md: "2.26vw" }}
                      maxWidth={{ xs: "352px", sm: "562px", md: "58.68vw" }}
                      pb={{ xs: "62px", sm: "99px", md: "10.30vw" }}
                    >
                      <img
                        src={item.image}
                        alt="account_dashboard_image "
                        width="100%"
                        // height="548"
                      />
                    </Box>
                  </Box>
                </Stack>
              </Box>
              <Box width="100%" position={"absolute"} bottom="-0.39vw">
                <img
                  src={afterBg}
                  // style={{ marginBottom: "-4px" }}
                  width="100%"
                  alt="curve_after_image"
                />
              </Box>
            </Box>
          ))}
        </Zoom>
      </Box>
    </Box>
  );
}
