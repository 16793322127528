import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NavBar from "../../component/layout/nav";
import MerchantSlideCard from "../../component/ui/services/merchant/slideCard";
import TimelineDisplay from "../../component/ui/timelineDisplay";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import heroBg from "../../images/services/merchant/heroBG.svg";
import heroDesktop from "../../images/services/merchant/heroDesktop.png";
import heroMobile from "../../images/services/merchant/heroMobile.svg";
import transactImage from "../../images/services/merchant/transact.svg";
import transactBgMobile from "../../images/services/merchant/transactMobile.svg";
import startBgDesktop from "../../images/services/merchant/startBg.png";
import startBgMobile from "../../images/services/merchant/startBgMobile.png";
import exploreImage from "../../images/services/merchant/explore.svg";
import exploreImageMobile from "../../images/services/merchant/exploreMobile.svg";
import faqBg from "../../images/services/merchant/faq.svg";
import dotDark from "../../images/general/dotDark.svg";

import GetStartedButton from "../../component/ui/getStartedButton";
import { BiChevronDown } from "react-icons/bi";

const usp = [
  {
    title: "Powerful dashboard to analyze sales record",
    text: "Get acquainted with Wallx merchant dashboard, which offers a record of your company's sales data",
  },
  {
    title: "Unified Marketing Tools",
    text: "WallX Offers your business access to send out announcements, sales, offer, new arrivals, and discounts to your loyal customers with just one click.",
  },
  {
    title: "Fast And Secured Payment For Your Customers",
    text: "Customers can generate a PAYCODE in your store or offline to make payments to you in a more secured, fast and easy way. Start accepting secured PayCode today as faster payment method",
  },
];

const transactMethods = [
  {
    title: "Pay Code Payment",
    text: "You can generate  a paycode to make payments for your purchases at a merchant store with ease.",
  },
  {
    title: "Custom Payment Link",
    text: "Generate a custom payment link to accept, verify and recieve payments from your customers.",
  },
  {
    title: "Debit Card",
    text: "Request a virtual or physical debit card for your business. Also generate a PayCode using any bank card.",
  },
  {
    title: "eVoucher",
    text: "Create an eVoucher/gift code voucher for friends, family and loyal customers.",
  },
];

const exploreSteps = [
  {
    title: "SMS",
    text: "Send short messages as notifications and sales updates to customers.",
  },
  {
    title: "Email",
    text: "Send out email announcements to your new and existing customers.",
  },
  {
    title: "Sales Record",
    text: "Keep track of your sales records and generate customizable reports for your business.",
  },
];

const startSteps = [
  { title: "Create a WallX Merchant account" },
  { title: "Setup Your Store" },
  { title: "Start Recieving Payment" },
];

const faq = [
  {
    title: "What to Know About Wallx Merchant?",
    text: "Wallx for Merchants is a tool that allows businesses and people to easily combine business operations with social/lifestyle transactions.",
  },
  {
    title: "How to Sign up? ",
    text: "You must register and upload a method of business verification (CAC certificate) in order to build a business profile on Wallx Merchant. Enter all of the information provided on the signup form to explore business tool.",
  },
  {
    title: "How long does it take to verify my account",
    text: "Verification reviews often take 5-7 business days. After evaluation, WallX will send you an email to confirm. If you have not received an email confirmation after 7 business days, kindly get in touch with us.",
  },
];

export default function MerchantPage() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        // mt="-80px"
        // pb={{ xs: "73px", sm: "116px", md: "165px" }}
        position="relative"
        color="white"
        // px={{ sm: "16px", md: "23px" }}
        sx={{
          background: {
            xs: "linear-gradient(94.47deg, rgba(244, 234, 248, 0.931) -5.01%, rgba(190, 166, 226, 0.98) 101.61%)",
            md: `url(${heroBg})`,
          },
          backgroundSize: { md: "cover" },
          backgroundRepeat: { md: "no-repeat" },
        }}
        minHeight={{ xs: "393px", md: "48.611111111111114vw" }}
        // pt={{ xs: "12px", sm: "22px", md: "34px" }}
      >
        <Box
          pl={{ xs: "24px", sm: "66px", md: "7.002314814814815vw" }}
          pr={{ xs: "24px", sm: " 27px", md: "2.78vw" }}
        >
          <NavBar dark />
        </Box>
        <Stack
          direction={{ xs: "column", md: "row" }}
          // pl={{ sm: "54px", md: "77px" }}
          // mt={{ xs: "23px", md: "41px" }}
          // textAlign="center"
          // alignItems="center"
        >
          <Box
            mt={{ xs: "39px", md: "5.960648148148148vw" }}
            ml={{ xs: "23px", md: "7.002314814814815vw" }}
            maxWidth={{ xs: "306px", sm: " 560px", md: "58.45vw" }}
          >
            <Typography
              // letterSpacing="0"
              variant="h1"
              // fontSize="5.384615384615385em"
              color="primary"
              fontWeight="400"
              fontSize={{ xs: "31px", sm: " 39px", md: "4.05vw" }}
              // lineHeight="125.5%"
            >
              A Unified Business Tool for SME's and Individuals
            </Typography>
            <GetStartedButton
              href="https://business.wallx.co/register"
              variant="contained"
              gradient={isMobile ? false : true}
              dark={isMobile ? false : true}
              sx={{
                mt: { xs: "23px", sm: "1.7361111111111112vw" },
                // color: "white",
                // bgcolor: theme.palette.primary.main,
                // ":hover": { bgcolor: theme.palette.primary.main },
              }}
            />
          </Box>
          <Box
            width={{ sm: "361px", md: "38.36805555555556vw" }}
            // mr={{ xs: "22px", sm: "35px", md: "49px" }}
            ml={{ xs: "0px", sm: "auto", md: "-53px" }}
            mr={{ xs: "43px", sm: "auto" }}
            mb={{ xs: "-194px", md: "15px" }}
            // display={{ xs: "none", md: "block" }}
            mt={{ xs: "44px", md: "2.488425925925926vw" }}
          >
            <img
              src={isMobile ? heroMobile : heroDesktop}
              width="100%"
              alt="hero_phone"
            />
          </Box>
        </Stack>
      </Box>

      <Grid
        className="slide-container"
        justifyContent={"center"}
        columnSpacing={{ xs: "9px", sm: "14px", md: "20px" }}
        rowSpacing={{ xs: "9px", sm: "14px", md: "20px" }}
        mt={{ xs: "261px", md: "6.19vw" }}
        // mt={{ xs: "261px", md: "83px" }}
        px={{ xs: "3px", sm: "0px" }}
      >
        <Slide arrows={false} slidesToShow={isMobile ? 1 : 3}>
          {usp.map((item) => (
            <Box
              item
              className="each-slide"
              key={item.title}
              xs={12}
              sm={4}
              height="100%"
              mx="20px"
            >
              <MerchantSlideCard {...item} />
            </Box>
          ))}
        </Slide>
      </Grid>

      <Box mt={{ xs: "52px", sm: "83px", md: "8.68vw" }}>
        <Box
          mx={{ xs: "23px", sm: "55px", md: "5.79vw" }}
          maxWidth={{ xs: "89.89vw", sm: "473px", md: "49.42vw" }}
        >
          <Typography variant="h4" fontWeight="500">
            Transact Seamlessly with Your Customers using Safe & Secured Payment
            Methods.
          </Typography>
        </Box>

        <Stack
          mt={{ xs: "21px", sm: "34px", md: "3.53vw" }}
          direction={{ xs: "column", md: "row" }}
          columnGap={{ xs: "27px", sm: "43px", md: "4.51vw" }}
          rowGap={"55px"}
        >
          <Box
            width="100%"
            ml={{ xs: "23px", sm: "55px", md: "5.79vw" }}
            px={{ xs: "23px", md: "0" }}
            maxWidth={{ md: "32.24vw" }}
          >
            <TimelineDisplay
              data={transactMethods}
              rowGap={{ xs: "33px", sm: "52px", md: "5.44vw" }}
              titleProps={{
                variant: "h5",
                color: "primary",
                fontWeight: "500",
              }}
              textProps={{
                variant: "body2",
                fontWeight: "400",
              }}
            />
          </Box>
          <Box position="relative" width="100%" maxWidth={{ md: "59.84vw" }}>
            <Box>
              <img
                src={isMobile ? transactBgMobile : transactImage}
                width="100%"
                alt="transact_image"
              />
            </Box>
            {/* <Box position="absolute" bottom="0" zIndex={"-1"} right="0">
              <img
                src={transactBgDesktop}
                width="100%"
                alt="transact_background_desktop"
              />
            </Box> */}
          </Box>
        </Stack>
      </Box>

      <Box
        mt={{ xs: "27.11vw", sm: "65px", md: "6.83vw" }}
        px={{ xs: "39px", md: "89px" }}
      >
        <Typography textAlign={"center"} variant="h4" fontWeight="500">
          Start Selling Multiple Products & Accepting Payments Securely
        </Typography>
        <Box
          display={{ xs: "none", sm: "block" }}
          mt={{ xs: "6.67vw", md: "2.14vw" }}
        >
          <Stepper
            connector={<StepConnector sx={{ mx: { sm: "5px", md: "24" } }} />}
          >
            {startSteps.map((item, index) => (
              <Step key={item.title}>
                <StepLabel
                  StepIconComponent={() => (
                    <Box width={{ xs: "14px", md: "1.50vw" }}>
                      {index ? (
                        // <FaRegDotCircle
                        //   fontSize={"24px"}
                        //   color={theme.palette.primary.main}
                        // />
                        <img
                          src={dotDark}
                          width="100%"
                          height={"100%"}
                          alt="dotdarkicon"
                        />
                      ) : null}
                    </Box>
                  )}
                >
                  {item.title}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>

      <Box
        sx={{
          background: `url(${isMobile ? startBgMobile : startBgDesktop})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        mt={{ xs: "17px", sm: "28px", md: "2.89vw" }}
        py={{ xs: "75px", sm: "120px", md: "12.56vw" }}
        px={"34px"}
        // minHeight={{ xs: "205px", sm: "328px", md: "465px" }}
      >
        <Box
          maxWidth={{ xs: "365px", sm: "583px", md: "60.82vw" }}
          mx="auto"
          textAlign={"center"}
        >
          <Typography sx={{ color: "white" }} variant="h1" fontWeight={"600"}>
            WallX Business Suite Is Used By Millions Of Businesses.
          </Typography>
        </Box>
      </Box>

      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "flex-end", md: "space-between" }}
        // alignItems={{ xs: "center" }}
        columnGap={{ xs: "39px", sm: "87px", md: "127px" }}
        pl={{ sm: "83px", md: "8.68vw" }}
      >
        <Box
          pl={{ xs: "23px", sm: "0" }}
          mt={{ xs: "22.67vw", sm: "83px", md: "8.62vw" }}
          maxWidth={{ md: "30.79vw" }}
        >
          <Typography
            maxWidth={{ xs: "57.78vw", md: "25.98vw" }}
            variant="h4"
            fontWeight={"500"}
          >
            Explore Tools For Customer Marketing
          </Typography>

          <Box mt={{ xs: "9px", sm: "14px", md: "1.45vw" }}>
            <TimelineDisplay
              data={exploreSteps}
              rowGap={{ xs: "22px", md: "2.37vw" }}
              titleProps={{
                variant: "h5",
                color: "primary",
                fontWeight: "500",
              }}
              textProps={{
                variant: "body2",
              }}
            />
          </Box>
        </Box>
        <Box
          mt={{ xs: "36px", sm: "58px", md: "6.08vw" }}
          width="100%"
          maxWidth={{ md: "50.23vw" }}
          pr={{ xs: "3.80vw", sm: "0" }}
          // ml={{ xs: "39px", sm: "97px", md: "137px" }}
          // maxWidth={{ md: "671px" }}
        >
          <img
            src={isMobile ? exploreImageMobile : exploreImage}
            width="100%"
            alt="explore_image"
          />
        </Box>
      </Stack>

      <Box mt={{ xs: "22.22vw",  md: "2.60vw" }}>
        <Box maxWidth={{ md: "40.63vw" }} mx="auto">
          <Box
            // maxWidth={{ xs: "240px", sm: "383px", md: "542px" }}

            textAlign="center"
          >
            <Typography variant="h5" fontWeight={"500"}>
              Frequently asked questions
            </Typography>
          </Box>
          <Box
            sx={{
              background: { xs: "none", sm: `url(${faqBg})` },
              backgroundSize: { sm: "cover" },
              backgroundRepeat: { sm: "no-repeat" },
            }}
            mr={{ xs: "1px", sm: "2px", md: "3px" }}
            pt={{ xs: "20px", sm: "32px", md: "45px" }}
            px={{ xs: "32px", sm: "82px", md: "0" }}
            pb={{ xs: "34px", sm: "54px", md: "77px" }}
          >
            <Box mx="auto">
              {faq.map((item) => (
                <Accordion key={item.title}>
                  <AccordionSummary
                    expandIcon={
                      <BiChevronDown
                        fontSize="19px"
                        color={theme.palette.primary.main}
                      />
                    }
                  >
                    <Typography variant="subtitle1">{item.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography> {item.text} </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
