import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import AppTheme from "../../style/theme/main";

import { FaChevronCircleRight, FaTimes } from "react-icons/fa";

import logoDark from "../../images/general/logoDark.svg";
import { GiTakeMyMoney } from "react-icons/gi";
import { BsCart4 } from "react-icons/bs";
import { BiStore } from "react-icons/bi";
import { IoMdArrowDropdown } from "react-icons/io";

const links = [
  { title: "Home", to: "/" },
  { title: "About", to: "/about" },
  {
    title: "Service",
    path: "/service",
    children: [
      { icon: GiTakeMyMoney, title: "WallX POS ", to: "/pos" },
      { icon: BsCart4, title: "Business Suite", to: "/business-suite" },
      { icon: BiStore, title: "WallX Lifestyle", to: "/lifestyle" },
    ],
  },
  { title: "Pricing  ", to: "/pricing" },
  { title: "Contact  ", to: "/contact" },
];

export default function AppDrawer({ open, close }) {
  return (
    <Drawer
      open={open}
      onClose={close}
      sx={{
        display: { xs: "block", md: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: "100%",
          maxWidth: "320px",
        },
      }}
    >
      <Stack
        // width="100%"
        direction={"row"}
        paddingX={"10px"}
        paddingY={"10px"}
        justifyContent="space-between"
        spacing="1px"
        mb={"16px"}
        alignItems={"center"}
        key={11111}
      >
        <Box maxWidth={"60px"} key={2322}>
          <img src={logoDark} alt="logo" height={40} />
        </Box>
        <IconButton onClick={close}>
          <FaTimes style={{ width: "24px", height: "24px" }} />
        </IconButton>
      </Stack>
      <List>
        {links.map((item, index) => (
          <div key={index}>
            {item.children?.length ? (
              <>
                <Accordion
                  elevation={0}
                  sx={{
                    borderBottom: "1px solid rgba(255,255,255,0.5)",
                    borderRadius: "0",
                  }}
                  key={`${index+'child'}`}
                >
                  <AccordionSummary expandIcon={<IoMdArrowDropdown />}>
                    <Typography key={'ll1'}> {item.title} </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {item.children.map((child, indexxx) => (
                      <NavLink
                        to={`${item.path}${child.to}`}
                        key={indexxx}
                        style={({ isActive }) => ({
                          textDecoration: "none",
                          color: isActive
                            ? AppTheme.palette.primary.main
                            : "#111111",
                        })}
                      >
                        <Divider />
                        <ListItem disablePadding>
                          <ListItemButton key={'cc1'}>
                            <ListItemText primary={child.title} />
                          </ListItemButton>
                        </ListItem>
                      </NavLink>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Divider />
              </>
            ) : (
              <NavLink
                to={item.to}
                key={index+2}
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? AppTheme.palette.primary.main : "#111111",
                })}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </NavLink>
            )}
          </div>
        ))}
        <ListItem
          disablePadding
          sx={{ bgcolor: AppTheme.palette.primary.main, color: "white" }}
          secondaryAction={<FaChevronCircleRight />}
        >
          <ListItemButton href="https://business.wallx.co" target="_blank">
            <ListItemText primary={"Login"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          sx={{ color: AppTheme.palette.primary.main }}
          secondaryAction={
            <FaChevronCircleRight color={AppTheme.palette.primary.main} />
          }
        >
          <ListItemButton
            href="https://business.wallx.co/register"
            target="_blank"
          >
            <ListItemText primary={"Sign Up"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
