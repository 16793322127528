import { Box, Grid, Stack, Typography, List, ListItem, ListItemText, useMediaQuery } from "@mui/material";
import {Zoom, Slide} from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import {images} from "../images/about/index";
import CTABlock from "../component/ui/cta";
import { FaCircle } from "react-icons/fa"
import AppTheme from "../style/theme/main"
import "../style/css/about.css"
import NavBar from "../component/layout/nav";
import { useTheme } from "@emotion/react";

const slideImages = [images.black_box, images.sterling, images.flutterwave, images.monnify, images.providus, images.wema, images.paystack, images.verifyme,]
const slideImages2 = [images.black_box, images.sterling, images.flutterwave, images.monnify, images.providus, images.wema, images.paystack, images.verifyme,].reverse();
const slideImages3 = [images.sterling, images.black_box, images.monnify,  images.flutterwave,  images.wema, images.providus, images.verifyme,images.paystack,]


const data = [
  "WallX Africa is a customer-centric company born out of Africa.",
  "Our vision is to enable businesses and individuals to attain seamless transactions.",
  "Providing individuals and SMEs with the ability to scale by transparently coordinating their day-to-day business activities ",
  "offers simplified access to various financial and lifestyle services",
];

const analysis = [
  {
    title: 3000,
    data: "Users in 1 year",
  },
  {
    title: 10,
    data: "Years of experience",
  },
  {
    title: "100%",
    data: "Quality",
  },
]

const customers = [
  {
    sub1: images.woman1b,
    main: images.man1,
    text: "I use WallX to perform all my financial transactions,including online bill payment and purchasing foodstuff online. I will undoubtedly suggest Wallx to others.",
    name: "James Akor",
    sub2: images.woman2b,
  },
  {
    sub1: images.man1b,
    main: images.woman2,
    text: "I use WallX to perform all my financial transactions,including online bill payment and purchasing foodstuff online. I will undoubtedly suggest Wallx to others.",
    name: "James Akor",
    sub2: images.woman1b,
  },
  {
    sub1: images.woman2b,
    main: images.woman1,
    text: "I use WallX to perform all my financial transactions,including online bill payment and purchasing foodstuff online. I will undoubtedly suggest Wallx to others.",
    name: "James Akor",
    sub2: images.man1b,
  },
]
const peopleImages1 = [images.picture1b,images.picture2b,images.picture3b,images.picture4b];
const peopleImages2 = [images.picture2s,images.picture3s,images.picture5s,images.picture2s];

export default function AboutPage(){
const theme = useTheme();
const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <Box px={{xs:"6.20vw", md:"2.78vw"}}>
      <NavBar light />

      </Box>
        <Grid container className="background-img" sx={{padding: "50px 0",}}>
            <Grid item md={12} xs={12} className="background-img-mobile">
               <Stack sx={{margin:{md: "100px"}, padding:{xs:"80px 0 0 30px", md:"0"}}}>
                  <Typography variant="h4" sx={{paddingLeft: {md:"10px",xs:"10px"}, fontWeight: {xs:"600"}, maxWidth:{xs: "190px",md: "inherit"}, fontSize:{md: "75px", xs: "35px"}}}>What Makes <br className="mobile-break" /> Us Unique</Typography>
                  <List disablePadding sx={{fontSize: "20px",display: {xs: "none", md: "block", sm: "block"}}}>
                  {
                    data.map((item) => (
                  <ListItem disablePadding disableGutters key={item} sx={{marginTop: "20px"}}>
                    <FaCircle
                      style={{ marginRight: "5px" }}
                      color={AppTheme.palette.primary.main}
                    />
                    <ListItemText sx={{maxWidth: "40%"}} primary={item} />
                  </ListItem>
                      )
                    )
                  }
                  </List>
                  <Box sx={{padding:{md:"10px", xs:"10px 0 0 10px"}, marginTop: "20px", display: "flex", flexDirection: {xs:"column", md:"row", sm:"row"}} }>
                    {
                      analysis.map((item, index) => {
                        return (
                          <Stack key={index} sx={{textAlign: {xs: "left", md: "center"}, marginTop: {xs: "20px"}, paddingRight: "70px"}}>
                            <Typography color="primary" variant="h6" sx={{fontWeight: "900", fontSize:{md:"50px",xs:"20px"}}}>{item.title}</Typography>
                            <Typography sx={{fontSize:{md: "16px", xs:"12px"}}}>{item.data}</Typography>
                          </Stack>
                        )
                      })
                    }
                  </Box>
                  
               </Stack>
            </Grid>
            <Grid item display="flex" sx={{margin: "20px 0", flexDirection: {md: "row", xs: "column-reverse"}}}>
              <Box sx={{maxWidth: {md:"700px", xs:"350px"}, width: {md:"50%", xs:"100%"}, paddingLeft: "20px", maxheight: {md:"300px", xs: "50px"}}} >
              <div>
                  <Zoom scale={1} arrows={false} duration={3000} transitionDuration={10} pauseOnHover={false}>
                      {peopleImages1.map((each, index) => (
                        <div key={index} style={{width: "100%",}}>
                          <img style={{ objectFit: "contain", width: "100%",}} alt="person" src={each} />
                        </div>
                      ))}
                  </Zoom>
                </div>
                <div>
                  <Zoom scale={1} arrows={false} duration={3000} transitionDuration={10} pauseOnHover={false}>
                      {peopleImages2.map((each, index) => (
                        <div key={index} style={{width: "100%", marginTop: "20px"}}>
                          <img style={{ objectFit: "contain", width: "100%"}} alt="person" src={each} />
                        </div>
                      ))}
                  </Zoom>
                </div>
              </Box>
              <Box sx={{width: {md: "50%"}, padding: {xs: "20px"}}}>
                <Typography variant="h5" sx={{marginTop: "50px", textAlign: "justify", padding: {md: "100px", xs: "10px" }, fontWeight: '700'}}>
                  Wallx offers simplified access to various 
                  financial and lifestyle services to help your business grow 
                  and, scale across Africa.
                </Typography>
              </Box>
            </Grid>
        </Grid>
        <Stack sx={{ textAlign: { md: "center", xs: "left"}, padding: {xs: "0 20px"},  marginTop: { xs: "5px", md: "100px",},  marginBottom: "40px"}}>
          <Typography variant="h5" sx={{fontWeight: '700'}}>WallX Partners With</Typography>
          <Typography>Our vision is to drive and deepen financial inclusion globally.</Typography>
        </Stack>
        <Box style={{height: "fit-content", margin: "50px 0"}}>
        {
          mobile ? (
            <Box sx={{  display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div className="image-container" >
            <Zoom scale={1} arrows={false} duration={3000} transitionDuration={10} pauseOnHover={false}>
              {
                slideImages.map((slideImage, index) => (
                  <div className="each-slide"  key={index}>
                    <img className="img" alt="people" src={slideImage} />
                  </div>
                ))
              }
            </Zoom>
            </div>
            <div className="image-container">
            <Zoom scale={1} arrows={false} duration={3000} transitionDuration={10} pauseOnHover={false}>
              {
                slideImages2.map((slideImage, index) => (
                  <div className="each-slide" key={index}>
                    <img className="img" alt="people" src={slideImage} />
                  </div>
                ))
              }
            </Zoom>
            </div>
            <div className="image-container">
            <Zoom scale={1} arrows={false} duration={3000} transitionDuration={10} pauseOnHover={false}>
              {
                slideImages3.map((slideImage, index) => (
                  <div className="each-slide" key={index}>
                    <img className="img" alt="people" src={slideImage} />
                  </div>
                ))
              }
            </Zoom>
            </div>
            </Box>
          ) : (
            <Box sx={{ maxWidth: "100%", display: "flex", justifyContent: "space-around "}}>
            <div className="image-container">
            <Zoom scale={1} arrows={false} duration={3000} transitionDuration={10} pauseOnHover={false}>
              {
                slideImages.map((slideImage, index) => (
                  <div className="each-slide" key={index}>
                    <img className="img" style={{ padding: "0 25px"}} alt="people" src={slideImage} />
                  </div>
                ))
              }
            </Zoom>
            </div>
            <div className="image-container">
            <Zoom scale={1} arrows={false} duration={3000} transitionDuration={10} pauseOnHover={false}>
              {
                slideImages2.map((slideImage, index) => (
                  <div className="each-slide" key={index}>
                    <img className="img" style={{ padding: "0 25px"}} alt="people" src={slideImage} />
                  </div>
                ))
              }
            </Zoom>
            </div>
            <div className="image-container">
            <Zoom scale={1} arrows={false} duration={3000} transitionDuration={10} pauseOnHover={false}>
              {
                slideImages3.map((slideImage, index) => (
                  <div className="each-slide" key={index}>
                    <img className="img" style={{ padding: "0 25px"}} alt="people" src={slideImage} />
                  </div>
                ))
              }
            </Zoom>
            </div>
            </Box>
          )
        }
        <Box sx={{ display: {md: "flex", xs: "none"}, justifyContent: "center", margin: "10px 0"}}>
          <Box className="slide-container" sx={{width: "95%", height: "fit-content", margin: "50px 0"}}>
            <Typography sx={{marginLeft: "25px", fontSize: "20px",color: "#8E51DDED"}}>Our Customers Experience</Typography>
              <Slide transitionDuration={10}>
               {customers.map((slideImage, index)=> (
                  <Box className="each-slide" key={index} sx={{}}>
                    <Grid container sx={{display: "flex", width: "100%",}}>
                      <Grid item md="4.2" sx={{ display: "flex", justifyContent: "space-between", }}>
                        <img width="100" height="100%" src={slideImage.sub1} alt="person" />
                        <img width="300" height="100%" src={slideImage.main} alt="person" />
                      </Grid>
                      <Grid md="5.8" item sx={{paddingLeft: "30px", }}>
                        <img src={images.comment} alt="message icon" />
                        <Typography component="div" sx={{margin: "10px 0",fontSize: "22px",width: "600px"}}>{slideImage.text}</Typography>
                        <Typography sx={{marginTop: "20px", fontSize: "19px", fontWeight: "bold", color: "#443792"}}>{slideImage.name}</Typography>
                      </Grid>
                      <Grid item md="2" sx={{textAlign: "right",}}>
                        <img width="100" height="100%" src={slideImage.sub2} alt="person" />
                      </Grid>
                    </Grid>
                  </Box>
                ))} 
              </Slide>
            </Box>
          </Box>
        </Box>
        <Box >
          <CTABlock />
        </Box>
    </Box>
  )
}