import { Button, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { FaApple, FaGooglePlay } from "react-icons/fa"

export function AppleStoreButton({ dark }) {
  return (
    <Button
      variant="contained"
      disableElevation
      href={"https://apps.apple.com/ng/app/wallx-africa/id1575698575"}
      target="_blank"
      sx={{
        color: dark ? "white" : "black",
        fontSize:{xs:"24px",  md:"1.39vw"},
        bgcolor: dark ? "black" : "white",
        textTransform: "capitalize",
        textDecoration: "none",
        ":hover": {
          color: dark ? "white" : "black",
          bgcolor: dark ? "black" : "white",
        },
      }}
      startIcon={<FaApple fontSize="inherit" />}
    >
      <Box  textAlign={"left"}>
        <Typography fontWeight={"300"} fontSize={{xs:"9px", md:"0.69vw"}} noWrap>
          Download on the
        </Typography>
        <Typography  variant="body1" fontWeight={"600"} noWrap>
          App Store
        </Typography>
      </Box>
    </Button>
  )
}

export function PlayStoreButton({ light }) {
  return (
    <Button
      variant="outlined"
      disableElevation
      href={"https://play.google.com/store/apps/details?id=co.wallx.android"}
      target="_blank"
      sx={{
        color: light ? "white" : "black",
        borderColor: light ? "white" : "black",
        textDecoration: "none",
        textTransform: "capitalize",
        ":hover": {
          color: light ? "white" : "black",
          borderColor: light ? "white" : "black",
        },
      }}
      startIcon={<FaGooglePlay size={"32px"} />}
    >
      <Box textAlign={"left"}>
        <Typography
          fontWeight={"300"}
          fontSize={{xs:"9px", md:"0.69vw"}}
          noWrap
        >
          Download on the
        </Typography>
        <Typography variant="body1" fontWeight={"600"} noWrap>
          Google Play
        </Typography>
      </Box>
    </Button>
  )
}
