import { Box, Stack, Typography } from "@mui/material";

export default function SlideCard(props) {
  return (
    <Stack
      pt={{ xs: "42px", sm: "68px", md: "7.06vw" }}
      justifyContent="space-between"
      bgcolor="white"
      minHeight={{ xs: "199px", sm: "251px", md: "26.19vw" }}
      maxWidth={{ md: "48.78vw" }}
      borderBottom={{
        xs: "14px solid linear-gradient(107.33deg, rgba(41, 33, 90, 0.9) -1.88%, rgba(159, 114, 217, 0.792) 100.64%)",
        md: "25px solid linear-gradient(107.33deg, rgba(41, 33, 90, 0.9) -1.88%, rgba(159, 114, 217, 0.792) 100.64%)",
      }}
    >
      <Box pb={{ xs: "23px" }} px={{ xs: "23px", md: "1.74vw" }}>
        <Typography color="primary" fontWeight={"500"} variant="h4">
          {props.title}
        </Typography>
        <Typography mt={{ xs: "8px", sm: "11px", md: "15px" }} variant="h5">
          {props.text}
        </Typography>
      </Box>
      <Box
        height={{ xs: "14px", sm: "18px", md: "1.85vw" }}
        sx={{
          background:
            "linear-gradient(107.33deg, rgba(41, 33, 90, 0.9) -1.88%, rgba(159, 114, 217, 0.792) 100.64%)",
        }}
      />
    </Stack>
  );
}
