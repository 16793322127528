import { Typography, Link as MuiLink } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import RoundButton from "./roundButton";

const LinkComponent = ({ children, to, href, target }) => {
  return to ? (
    <Link to={to}>{children}</Link>
  ) : (
    <MuiLink href={href} target={target}>
      {children}
    </MuiLink>
  );
};

export default function GetStartedButton({
  text = "Get Started",
  sx = {},
  target = "_blank",
  dark,
  bgcolor,
  color,
  gradient,
  href = "",
  to = "",
  textProps = {},
  variant="contained",
  iconProps = {},
  ...props
}) {
  const theme = useTheme();

  const bgColor =  bgcolor || dark ? theme.palette.primary.main : "white"
  const textColor =  color || dark ? "white" : theme.palette.primary.main
  
  return (
    <LinkComponent to={to} href={href} target={target}>
      <RoundButton
        variant={variant}
        className={gradient ? "button" : ""}
        sx={{
          borderRadius:{xs:"30px", md:"2vw"},
          bgcolor: bgColor,
          display: "inline-block",
          color: textColor,
          px: "16px",
          width: "100%",
          // borderRadius: "1.6vw",
          // minHeight: { xs: "33px", md: "3.62962962962963vw" },
          maxWidth: { xs: "fit-content",  md: "17.36111111111111vw" },

          minHeight: { xs: "43px",sm:'44px', md: "4.62962962962963vw" },
          minWidth: {  md: "17.36111111111111vw" },
          ":hover": {
            bgcolor: bgColor,
            color: textColor,
          },
          ...sx,
        }}
        {...props}
      >
        <Stack
        sx={{

          fontSize:{ xs: "19px", md: "1.16vw" }
        }}
          direction="row"
          alignItems={"center"}
          columnGap={{ xs: "23px", md: "15px" }}
          justifyContent="center"
        >
          <Typography
            // fontSize={"inherit"}
            variant="body1"
            noWrap
            fontWeight="600"
            {...textProps}
          >
            {text}
          </Typography>
          <IoIosArrowDroprightCircle
            fontSize="inherit"
            color={textColor}
            {...iconProps}
          />
        </Stack>
      </RoundButton>
    </LinkComponent>
  );
}
