import { Grid, Button, Stack, Typography, Box } from "@mui/material";
import "./style/style.css";
import Navbar from "../component/layout/nav";

export default function ContactPage() {
  return (
    <>
      <div>
      <Box px={{xs:"6.20vw", md:"2.78vw"}}>

          <Navbar />
        </Box>
        <Grid className="background" container justifyContent="center">
          <Grid item md={4} sm={4} sx={{ margin: "60px auto" }}>
            <Box sx={{}}>
              <Typography
                sx={{ fontWeight: "700", marginTop: "40px", fontSize: "40px" }}
              >
                Contact Us
              </Typography>
              <form>
                <Stack spacing={2} mt={1}>
                  <div class="form__group">
                    <input
                      type="text"
                      id="fullname"
                      class="form__field"
                      placeholder="Enter your full name"
                    />
                    <label for="fullname" class="form__label">
                      Full Name
                    </label>
                  </div>
                  <div class="form__group">
                    <input
                      type="phone"
                      id="phone"
                      class="form__field"
                      placeholder="Enter your phone number"
                    />
                    <label for="phone" class="form__label">
                      Phone Number
                    </label>
                  </div>
                  <div class="form__group">
                    <input
                      type="email"
                      id="email"
                      class="form__field"
                      placeholder="Enter your email address"
                    />
                    <label for="email" class="form__label">
                      Email Address
                    </label>
                  </div>

                  <div class="form__group">
                    <textarea
                      style={{ resize: "none" }}
                      id="message"
                      class="form__field"
                      placeholder="Enter your message"
                      rows="4"
                    ></textarea>
                    <label for="message" class="form__label">
                      Message
                    </label>
                  </div>
                  <Button
                    mt={4}
                    sx={{
                      maxWidth: "150px",
                      borderRadius: "30px",
                      textTransform: "none",
                      fontSize: "12px",
                      backgroundColor: "#4640B9",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#4640B9",
                        color: "white",
                      },
                    }}
                  >
                    Send Message
                  </Button>
                </Stack>
              </form>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
