import React from "react";
import {
  Grid,
  Stack,
  Typography,
  Box,
  Button,
  TextField,
  //   FormGroup,
  //   FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
// import icon from '../images/faq/icon.png'
import bg from "../images/form/bg-img.png";
import NavBar from "../component/layout/nav";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

// import "./style/form.css";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function Formpage() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        background: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
      pb={8}
      px={3}
    >
      <Box  ml={{ md: "100px" }}>
        <NavBar dark color="light" />
      </Box>

      <Box ml={{ md: "100px" }} mt={8}>
        <IconButton onClick={() => navigate(-1)}>
          <FaArrowLeft fontSize={"23px"} color="#E7E7E7" />
        </IconButton>
      </Box>
      <Grid container sx={{ color: "#E7E7E7" }} mt={4}>
        <Box sx={{ width: "100%", ml: { md: "100px", sm: "0px", xs: "0px" } }}>
          <Typography
            variant="h4"
            fontWeight={"500"}
            sx={{ textAlign: "center" }}
          >
            Application Form
          </Typography>
          <Typography
            mt={{ xs: "16px", sm: "26px", md: "37px" }}
            paragraph
            sx={{ fontFamily: "12px" }}
          >
            Personal Information
          </Typography>
          <form>
            <Stack spacing={2} sx={{}}>
              <div>
                <Typography>Full Name</Typography>
                <TextField
                  name="fullname"
                  type="text"
                  size="small"
                  sx={{
                    width: { md: "567px", xs: "100%" },
                    mt: "10px",
                    border: "1px solid #E7E7E7",
                    borderRadius: "10px",
                    opacity: "0.6",
                  }}
                />
              </div>
              <div>
                <Typography>Email Address</Typography>
                <TextField
                  name="email"
                  type="text"
                  size="small"
                  sx={{
                    width: { md: "567px", xs: "100%" },
                    mt: "10px",
                    border: "1px solid #E7E7E7",
                    borderRadius: "10px",
                    opacity: "0.6",
                  }}
                />
              </div>
              <div>
                <Typography>Phone Number</Typography>
                <TextField
                  name="phonenumber"
                  type="text"
                  size="small"
                  sx={{
                    width: { md: "567px", xs: "100%" },
                    mt: "10px",
                    border: "1px solid #E7E7E7",
                    borderRadius: "10px",
                    opacity: "0.6",
                  }}
                />
              </div>
              <div>
                <Typography>State</Typography>
                <TextField
                  name="state"
                  type="text"
                  size="small"
                  sx={{
                    width: { md: "567px", xs: "100%" },
                    mt: "10px",
                    border: "1px solid #E7E7E7",
                    borderRadius: "10px",
                    opacity: "0.6",
                  }}
                />
              </div>
              <div>
                <Typography>Address</Typography>
                <TextField
                  name="state"
                  type="text"
                  size="small"
                  sx={{
                    width: { md: "567px", xs: "100%" },
                    mt: "10px",
                    border: "1px solid #E7E7E7",
                    borderRadius: "10px",
                    opacity: "0.6",
                  }}
                />
              </div>

              <Box>
                <Typography sx={{ fontSize: "12px" }}>
                  Professional Background
                </Typography>
                <Stack spacing={2}>
                  <div style={{ marginTop: "20px" }}>
                    <Typography>Most Recent Degree</Typography>
                    <TextField
                      name="degree"
                      type="text"
                      size="small"
                      sx={{
                        width: { md: "567px", xs: "100%" },
                        mt: "10px",
                        border: "1px solid #E7E7E7",
                        borderRadius: "10px",
                        opacity: "0.6",
                      }}
                    />
                  </div>
                  <div>
                    <Typography>Are you currently employed?</Typography>
                    <Stack direction="row" alignItems="center" spacing={3}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Checkbox {...label} />
                        <Typography>Yes</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Checkbox {...label} />
                        <Typography>No</Typography>
                      </Stack>
                    </Stack>
                    <div>
                      {/* <Checkbox
        {...label}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
      /> */}
                    </div>
                  </div>
                  <div>
                    <Typography>Resume Upload</Typography>

                    <Button
                      component="label"
                      sx={{
                        width: { md: "200px", sm: "100%" },
                        padding: "10px",
                        background: "transparent",
                        border: "1px solid #E7E7E7",
                        marginLeft: "-60px",
                        mt: "15px",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "left",
                          marginRight: "auto",
                          textTransform: "none",
                          color: "#E7E7E7",
                        }}
                      >
                        Choose File
                      </Typography>
                      <input hidden accept="file/*" multiple type="file" />
                    </Button>
                  </div>
                  <div>
                    <Typography>Portfolio Link</Typography>
                    <TextField
                      name="portfoliolink"
                      type="text"
                      size="small"
                      sx={{
                        width: { md: "567px", xs: "100%" },
                        mt: "10px",
                        border: "1px solid #E7E7E7",
                        borderRadius: "10px",
                        opacity: "0.6",
                      }}
                    />
                  </div>
                  <Button
                    sx={{
                      width: { md: "567px", xs: "100%" },
                      padding: "15px",
                      background: "#FFFFFF",
                      color: "#201E4B",
                      textTransform: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Submit Application
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </form>
        </Box>
      </Grid>
    </Box>
  );
}
