import { createTheme } from "@mui/material/styles";

const typographyList = {
  large: { xs: "39px", sm: "39px", md: "4.05vw" },
  h1: { xs: "39px", sm: "39px", md: "4.05vw" }, //fontsize 70
  h2: { xs: "23px", sm: "33px", md: "3.47vw" }, //fontsize 60
  h3: { xs: "21px", sm: "28px", md: "2.89vw" }, //fontsize 50
  h4: { xs: "20px", sm: "22px", md: "2.31vw" }, //fontsize 40
  h5: { xs: "16px", sm: "19px", md: "1.74vw" }, //fontsize 30
  subtitle1: { xs: "16px", sm: "16px", md: "1.39vw" }, //fontsize 24
  body1: { xs: "12px", sm: "16px", md: "1.16vw" }, //fontsize 20
  body2: { xs: "12px", sm: "13px", md: "1.04vw" }, //fontsize 18
  caption: { xs: "11px", sm: "11px", md: "0.81vw" }, //fontsize 14
};

let AppTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      ta: 767,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#4640B9",
    },
    secondary: {
      main: "#111111",
    },
  },
  typography: {
    button: { textTransform: "none" },
    fontFamily: [
      "Montserrat",
      "Lato",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
};

AppTheme = createTheme(AppTheme);
Object.keys(typographyList).forEach((item) => {
  const typography = typographyList[item];
  AppTheme.typography[item] = {
    fontSize: `${typography.md}`,
    [AppTheme.breakpoints.only("xs")]: {
      fontSize: `${typography.xs}`,
    },
    [AppTheme.breakpoints.only("sm")]: {
      fontSize: `${typography.sm}`,
    },
  };
});

export default AppTheme;
