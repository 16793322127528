import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Link as MuiLink,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";

import footerWallet from "../../images/home/footerWallet.svg";
import logoDark from "../../images/general/logoDark.svg";
import footerBg from "../../images/general/footer.png";
import { Link } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  AiFillLinkedin,
  AiFillInstagram,
  AiFillTwitterSquare,
} from "react-icons/ai";

const pageLinks = [
  // { title: "Documentation", to: "/" },
  { title: "Pricing", to: "/pricing" },
  {
    title: "Services",
    to: "/service",
    children: [
      { title: "WallX POS ", to: "/pos" },
      { title: "Business Suite", to: "/business-suite" },
      { title: "WallX Lifestyle", to: "/lifestyle" },
    ],
  },
  { title: "Customers", to: "/customer" },
  // { title: "Features", to: "/" },
];

const customerLinks = [
  { title: "Team", to: "/team" },
  { title: "About", to: "/about" },
  // { title: "Careers ", to: "/" },
  { title: "FAQ ", to: "/faq" },
];

const socialLinks = [
  {
    icon: AiFillLinkedin,
    to: "https://www.linkedin.com/company/wallx/",
  },
  {
    icon: AiFillTwitterSquare,
    to: "https://twitter.com/wallx_africa?s=11&t=4Z11r4vBfwR9OfTnbUdPEw",
  },
  {
    icon: AiFillInstagram,
    to: "https://instagram.com/wallx.africa?igshid=YmMyMTA2M2Y=",
  },
];

const PageContent = () => {
  return (
    <List disablePadding dense key={1}>
      {pageLinks.map((item, indexx) => (
        <div key={item+indexx}>
          {item.children?.length ? (
            <>
              <Accordion
                elevation={0}
                sx={{
                  // borderBottom: "1px solid rgba(255,255,255,0.5)",
                  borderRadius: "0",
                  p: 0,
                  bgcolor: "transparent",
                  color: "inherit",
                }}
                key={indexx}
              >
                <AccordionSummary
                  sx={{ px: 0, py: 0 }}
                  expandIcon={<IoMdArrowDropdown color="white" />}
                  key={'accordion1'}
                >
                  <Typography variant="body2"> {item.title} </Typography>
                </AccordionSummary>
                <AccordionDetails key={'accordion11'}>
                  {item.children.map((child, index) => (
                    <Link
                      to={`${item.to}${child.to}`}
                      key={index}
                      style={{ color: "inherit", textDecoration: "none" }}

                      // style={({ isActive }) => ({
                      //   textDecoration: "none",
                      //   // color: isActive
                      //   //   ? AppTheme.palette.primary.main
                      //   //   : "#111111",
                      // })}
                    >
                      <Divider />
                      <ListItemButton
                        // sx={{ mt: { xs: "11px", md: "1.16vw" } }}
                        disableGutters
                        key={'list1'}
                      >
                        <ListItemText key={`${child.title+index}`}> {child.title} </ListItemText>
                      </ListItemButton>
                    </Link>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Divider />
            </>
          ) : (
            <Link
              to={item.to}
              key={indexx}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <ListItemButton
                // sx={{ mt: { xs: "11px", md: "1.16vw" } }}
                disableGutters
                key={'ll2'}
              >
                <ListItemText key={`${item.title+indexx}`}> {item.title} </ListItemText>
              </ListItemButton>
            </Link>
          )}
        </div>
      ))}
    </List>
  );
};

const ContactContent = () => {
  return (
    <Stack rowGap={{ xs: "11px", md: "1.16vw" }} key={'stack1'}>
      <Typography variant="body1" key={'typo1'}>hello@wallX.co</Typography>
      <Typography variant="body1" key={'typo2'}>+234(70) 6328-3502</Typography>
      <Typography variant="body1" display={{ xs: "block", sm: "none" }} key={'typo3'}>
        21 Nyese Ibrahim Tella Street, Oral Estate, Elegnaza Bus-stop, Lekki
        Expressway. Lagos
      </Typography>
    </Stack>
  );
};

const CompanyContent = () => {
  return (
    <List disablePadding dense>
      {customerLinks.map((item, id) => (
        <Link
          to={item.to}
          key={id}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <ListItemButton disableGutters>
            <ListItemText> {item.title} </ListItemText>
          </ListItemButton>
        </Link>
      ))}
    </List>
  );
};

const ImageContent = () => (
  <Box sx={{ mt: 2 }} maxWidth={{ xs: "142px", sm: "100px", md: "248px" }}>
    <img
      width="100%"
      // style={{ marginRight: "50px" }}
      src={footerWallet}
      alt=""
      key={'image1'}
    />
  </Box>
);

const SocialContent = () => (
  <Stack
    // mt={{ xs: "13px", sm: "26px", md: "41px" }}
    direction="row"
    flexWrap="wrap"
    columnGap={{ xs: "7px", sm: "12px", md: "1.22vw" }}
    fontSize={{ xs: "19px", md: "1.56vw" }}
    // display={{ xs: "none", md: "flex" }}
  >
    {socialLinks.map((item, id) => (
      <MuiLink
        style={{ textDecoration: "none" }}
        href={item.to}
        target="_blank"
        key={id}
      >
        <item.icon color="white" fontSize="inherit" />
      </MuiLink>
    ))}
  </Stack>
);

const LogoContent = () => (
  <Box sx={{ mt: 2 }}>
    <Box
      maxWidth={{ xs: "28px", sm: "28px", md: "2.95vw" }}
      height={{ xs: "17px", sm: "27px", md: "2.83vw" }}
      key={'bx1'}
    >
      <img src={logoDark} alt="" width="100%" />
    </Box>
    <Typography
      display={{ xs: "block", sm: "none" }}
      fontSize={{ xs: "11px" }}
      mt={{ xs: "13px", sm: "17px", md: "24px" }}
      key={'typobox1'}
    >
      {" "}
      All right reserved &copy; 2022{" "}
    </Typography>
  </Box>
);

const sections = [
  { title: "Pages", content: PageContent },
  { title: "Company", content: CompanyContent },
  { title: "Contact", content: ContactContent },
  { title: "Follow Us", content: SocialContent, mr: { sm: "4.7%" } },
];

export default function AppFooter() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        backgroundImage: `url(${footerBg})`,
        backgroundSize: "cover",
      }}
    >
      <Box
        boxSizing={"border-box"}
        // p={{ sm: "35px" }}
        color="white"
        bgcolor="rgba(32, 30, 75, 0.99)"
        key={'box1'}
      >
        <Box
          px={{ xs: "49px", sm: "78px", md: "8.16vw" }}
          pt={{ xs: "27px", sm: "43px", md: "4.46vw" }}
          // pb={{ xs: "40px", md: "42px" }}
          key={'boxx1'}
        >
          <Typography
            textAlign={{ xs: "center", md: "left" }}
            fontWeight="600"
            variant="h3"
            key={'typobox2'}
          >
            Share, Spend With Ease
          </Typography>
        </Box>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          rowGap="13px"
          py={{ xs: "22px", sm: "35px", md: "3.70vw" }}
          px={{ sm: "78px", md: "8.16vw" }}
          // flexWrap={"wrap"}
          justifyContent="space-between"
          // flexWrap={{ sm: "wrap",  }}
          key={'stack33'}
        >
          {sections.map((item, id) => (
            <Box
              key={id}
              width={{ xs: "100%", sm: "auto" }}
              // mr={item.mr || { xs: "51px", sm: "82px", md: "116px" }}
              // mr={item.mr || { xs: "51px", sm: "1.7%" }}
              maxWidth={{ sm: "106px", md: "12.05vw" }}
            >
              {isMobile ? (
                <Accordion
                  sx={{
                    bgcolor: "transparent",
                    borderTop: "1px solid rgba(255,255,255,0.3)",
                    // borderBottom: "1px solid rgba(255,255,255,0.1)",
                    
                  }}
                  style={{
                    borderRadius: "0px",
                  }}
                  elevation={0}
                  color="white"
                  key={'accordion13'}
                >
                  <AccordionSummary
                    expandIcon={
                      <IoMdArrowDropdown fontSize="19px" color="white" />
                    }
                  >
                    <Typography
                      fontWeight={"600"}
                      fontSize="19px"
                      color="white"
                      my={"6px"}
                    >
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ color: "white" }}>
                    <item.content />
                  </AccordionDetails>
                </Accordion>
              ) : (
                <>
                  <Typography
                    fontWeight={"600"}
                    // fontSize="19px"
                    variant="subtitle1"
                    my={"6px"}
                  >
                    {item.title}
                  </Typography>
                  <item.content />
                </>
              )}
            </Box>
          ))}
          <Box
            display={{ xs: "none", md: "flex" }}
            mt="-30px"
            // mt={{ xs: "27px", sm: "44px", md: "62px" }}
          >
            <ImageContent />
          </Box>
        </Stack>

        <Box key={'box2'}>
          <Divider sx={{ borderColor: "rgba(255,255,255,0.3)" }} />
          <Stack
            px={{ xs: "23px", sm: "78px", md: "8.16vw" }}
            display={{ xs: "flex", md: "none" }}
            // mt={"25px"}
            flexWrap="wrap"
            direction="row"
            width="100%"
            justifyContent={"space-between"}
            alignItems="center"
            key={'stack43'}
          >
            <LogoContent />
            <ImageContent />
          </Stack>
          <Stack
            px={{ xs: "49px", sm: "78px", md: "8.16vw" }}
            display={{ xs: "none", sm: "flex" }}
            py={{ xs: "10px", sm: "17px", md: "1.74vw" }}
            flexWrap="wrap"
            direction={{ xs: "column", md: "row" }}
            alignItems="center"
            width="100%"
            justifyContent={"space-between"}
            key={'46'}
          >
            <Box display={{ xs: "none", md: "block" }} >
              <LogoContent />
            </Box>
            <Typography variant="body1">
              21 Nyese Ibrahim Tella Street, Oral Estate, Elegnaza Bus-stop,
              Lekki Expressway. Lagos
            </Typography>
            <Typography fontSize={{ xs: "13px", md: "0.93vw" }}>
              All right reserved &copy; 2022{" "}
            </Typography>
          </Stack>
        </Box>
        <Box display={{ xs: "none", sm: "block" }} textAlign="center" key={'box3'}></Box>
      </Box>
    </Box>
  );
}
