import React, { useState } from "react";
import {
  AppBar,
  Box,
  Typography,
  Stack,
  IconButton,
  ListItemText,
  ListItemButton,
  useTheme,
  Link,
  Divider,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../images/nav/logo.svg";
import { images } from "../../images/about/index";
import { BsCart4, BsList } from "react-icons/bs";
import { BiStore } from "react-icons/bi";
import { GiTakeMyMoney } from "react-icons/gi";
// import { BsXLg } from "react-icons/bs"
import AppDrawer from "./drawer";
import DropDownbutton from "../ui/dropdownButton";
import GetStartedButton from "../ui/getStartedButton";
// import styles from "./nav.module.css"

export default function NavBar({ dark, ...props }) {
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = () => setDrawer(!drawer);

  const theme = useTheme();

  const navigate = useNavigate();

  const links = [
    { title: "Home", path: "/" },
    { title: "About", path: "/about" },
    {
      title: "Service",
      path: "/service",
      children: [
        { icon: GiTakeMyMoney, title: "WallX POS ", path: "/pos" },
        { icon: BsCart4, title: "Business Suite", path: "/business-suite" },
        { icon: BiStore, title: "WallX Lifestyle", path: "/lifestyle" },
      ],
    },
    { title: "Pricing", path: "/pricing" },
    { title: "Contact", path: "/contact" },
  ];

  // const [toggle, setToggle] = useState(false)

  return (
    <AppBar
      position="relative"
      sx={{ zIndex: "1" }}
      color="transparent"
      elevation={0}
    >
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
        spacing={{ xs: "17px", sm: "28px", md: "2.89vw" }}
        // paddingY={{ xs: "14px", sm: "22px", md: "31px" }}
        pt={{ xs: "24px", sm: "28px", md: "2.89vw" }}
        sx={{ filter: "brightness(100%)" }}
        key={21}
      >
        <Box
          key={'bxl12'}
          width={{ xs: "50px", sm: "60px", md: "6.31vw" }}
          height={{ xs: "47px", sm: "57px", md: "5.96vw" }}
        >
          <NavLink to={"/"} style={{ textDecoration: "none" }} key={'bxl13'}>
            <img src={dark ? logo : images.logo} alt="logo" width={"100%"}  key={77}/>
            {/* <img src={images.logo} alt="logo" height={40} /> */}
          </NavLink>
        </Box>
        <Stack
          direction={"row"}
          spacing={{ xs: "17px", sm: "28px", md: "2.89vw" }}
          fontSize={{ xs: "8px", sm: "11px", md: "1.16vw" }}
          color={dark ? "white" : "black"}
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
          }}
          key={68}
        >
          {links.map((item, index) => (
            <div key={index+item}>
              {item.children ? (
                <DropDownbutton title={item.title} key={index}>
                  <>
                    {item.children.map((child, indexx) => (
                      <ListItemButton
                        key={indexx}
                        onClick={() => navigate(`${item.path}${child.path}`)}
                        sx={{
                          color: theme.palette.primary.main,
                          fontSize: { xs: "8px", sm: "11px", md: "1.16vw" },
                        }}
                      >
                        {
                          <child.icon
                            style={{
                              fontSize: "inherit",
                              marginRight: "1.85vw",
                              color: theme.palette.primary.main,
                            }}
                            key={'childicon'+indexx}
                          />
                        }
                        <ListItemText primary={child.title} key={'listitem'+indexx}/>
                      </ListItemButton>
                    ))}
                  </>
                </DropDownbutton>
              ) : (
                <Typography
                  onClick={() => navigate(item.path)}
                  sx={{ cursor: "pointer" }}
                >
                  {item.title}
                </Typography>
              )}
            </div>
          ))}
          <Stack
            direction="row"
            columnGap={{ xs: "7px", sm: "11px", md: "1.16vw" }}
            pl={{ xs: "44px", sm: "70px", md: "7.35vw" }}
            alignItems={"center"}
            key={99}
          >
            <Link
              sx={{ color: "inherit", textDecoration: "none" }}
              href="https://business.wallx.co"
              target="_blank"
              key={'l1'}
            >
              <Typography sx={{ cursor: "pointer" }}>Login</Typography>
            </Link>
            <Divider orientation="vertical" flexItem />
            <GetStartedButton
              dark={!dark}
              text="Sign Up"
              sx={{
                minHeight: { xs: "21px", sm: "33px", md: "3.47vw" },
                minWidth: { xs: "66px", sm: "105px", md: "11.00vw" },
              }}
              href={"https://business.wallx.co/register"}
              target="_blank"
              key={'sbutton'}
            />
          </Stack>
        </Stack>
        <IconButton
          onClick={toggleDrawer}
          sx={{
            display: {
              xs: "flex",
              md: "none",
              color: dark ? "white" : "black",
            },
          }}
          key={'icon1'}
        >
          <BsList
            // className={`${styles.toggle} ${dark ? styles.general : styles.about}`}
            size={40}
            key={'blist'}
          />
        </IconButton>
      </Stack>

      <AppDrawer open={drawer} close={toggleDrawer} />
    </AppBar>
  );
}
