import card from "./card.png";
import cardMobile from "./cardMobile.png";
import ecommerce from "./ecommerce.png";
import financialServices from "./financialServices.png";
import foodIndustry from "./foodIndustry.png";
import logistics from "./logistics.png";
import merchant from "./merchant.png";
import zipScan from "./zipScan.png";
import testimonial1 from "./testimonial1.png";
import testimonial2 from "./testimonial2.png";
import testimonial3 from "./testimonial3.png";
import testimonial4 from "./testimonial4.png";
import testimonial5 from "./testimonial5.png";
import lines from "./lines.png";
import testimonialFooter from "./testimonialFooter.png";
import group from "./group.png";


export const images = {
    card,
    cardMobile,
    ecommerce,
    financialServices,
    foodIndustry,
    logistics,
    merchant,
    zipScan,
    testimonial1,
    testimonial2,
    testimonial3,
    testimonial4,
    testimonial5,
    group,
    testimonialFooter,
    lines,
}