import { Box, Button, Stack, Typography, Input } from "@mui/material"
import { AppleStoreButton, PlayStoreButton } from "../../component/ui/storeButton"
import Lifestyle from "../../component/ui/lifestyle"
import NavBar from "../../component/layout/nav"
import "../../style/css/service.css"


//images
import woman from "../../images/services/happywoman.svg"
import ecclipse from "../../images/services/ellipse.svg"
import qr from "../../images/services/qr.svg"
import circle from "../../images/services/groupcircel.svg"
import wrapper from "../../images/services/wrapper.svg"

export default function ServicePagezz() {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Box
        sx={{
          // height: "550px",
          backgroundImage: `url(${woman})`,
          backgroundPosition: "center",

          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor:
            "linear-gradient(117.64deg, rgba(74, 70, 151, 0.52) 52.62%, rgba(50, 43, 176, 0.1196) 81.43%)",
          backgroundBlendMode: "darken",
        }}
      >
        <Box
          sx={{
            // mt: { xs: -22, sm: -24, md: -10 },
            width: "100%",
            // p: { xs: 0, md: 6 },
            background:
              "linear-gradient(117.64deg, rgba(74, 70, 151, 0.52) 52.62%, rgba(50, 43, 176, 0.1196) 81.43%)",
            zIndex: "1",
          }}
          width="100%"
          height="100%"
        >
          <Box sx={{ marginLeft: "30px" }}>
            {" "}
            <NavBar dark />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "100%" },
              mt: { xs: "78px", sm: "125px", md: "177px" },
              pb: { xs: "90px", sm: "143px", md: "203px" },
              textAlign: { xs: "center", md: "center" },

              // top: { xs: "22vh", md: "0" },
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "2rem", md: "3rem" },
                fontWeight: "400",
                mb: 2,
              }}
            >
              Giving You a Simpler Way To
            </Typography>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="center"
              alignItems="center"
              spacing={{ xs: "33px", sm: 2 }}
              sx={{ mt: 3 }}
            >
              <Button
                href="https://business.wallx.co"
                target="_blank"
                sx={{
                  border: "1px solid #fff",
                  px: 4,

                  color: "#fff",

                  borderRadius: "15px",
                  background: "transparent",
                }}
              >
                <span>Share</span>
              </Button>
              <Box sx={{ transform: { xs: "rotate(90deg)", sm: "rotate(0)" } }}>
                <img
                  src="https://res.cloudinary.com/dekbvdqnb/image/upload/v1659098048/Line_15_am8o30.png"
                  alt=""
                />
              </Box>
              <Button
                href="https://business.wallx.co"
                target="_blank"
                sx={{
                  border: "1px solid #fff",
                  px: 4,

                  color: "#fff",

                  borderRadius: "15px",
                  background: "transparent",
                  m: { xs: "0 auto", md: "0" },
                  mt: { xs: "25px", sm: "40px", md: "56px" },
                }}
              >
                <span>Spend</span>
              </Button>
              <Box sx={{ transform: { xs: "rotate(90deg)", sm: "rotate(0)" } }}>
                <img
                  src="https://res.cloudinary.com/dekbvdqnb/image/upload/v1659098048/Line_15_am8o30.png"
                  alt=""
                />
              </Box>

              <Button
                href="https://business.wallx.co"
                target="_blank"
                sx={{
                  border: "1px solid #fff",
                  px: 4,

                  color: "#fff",

                  borderRadius: "15px",
                  background: "transparent",
                }}
              >
                <span>Enjoy</span>
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>



      <Stack
        direction={{ xs: "column", sm: "row", md: 'row' }}
        alignItems={{ md: "center", sm: 'center', xs: 'flex-start' }}
        sx={{
          mt: 6,
          width: {
            xs: "100%",
            md: "85%",
            sm: "100%"
          },
          pt: { md: '20px', sm: '20px', },
          pb: { md: '20px', sm: '20px' },
          display: { sm: 'flex', md: 'flex' },
          gap: "20px",
        }}
        bgcolor={{ sm: '#FCF9FF', md: '#FCF9FF' }} >
        <Box
          sx={{ display: { xs: 'none', sm: 'none', md: 'initial' }, mt: 8 }}
          maxHeight="350px">
          <img src={ecclipse} height={350} alt="ecclipse"
          />
        </Box>
        <Box
          className="pinPaymentParentBox"
          sx={{
            pl: { sm: '23px', xs: '23px', }, ml: { md: '100px' }, pr: { xs: '23px', },
            width: { xs: "100%", sm: "50%", md: "35%" },
            height: { sm: "auto", md: "auto" },
          }}
        >
          <Typography variant="h2"
            sx={{
              fontSize: { xs: "17px", sm: "27px", md: "39px" },
              fontWeight: "500",
              lineHeight: "3.175rem",
              color: "#222222"
            }}
          >
            Pin Payment
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "1rem", md: "1rem", sm: '1rem' },
              color: "#696969",
              fontWeight: "bold",
            }}
          >
            WallX offers a simplified access to various financial and
            lifestyle services which we have summarized it as “Share, Spend
            with Ease.”
          </Typography>
          <Stack
            direction={'row'}
            gap={2}
            sx={{ mt: 4 }}
            className="storeIcon"
          >
            <Box>
              <AppleStoreButton dark />
            </Box>

            <Box>
              <PlayStoreButton />
            </Box>
          </Stack>
        </Box>

        <Box
          width={{ xs: "100%", sm: "50%", md: "40%" }}
          height={{ sm: "auto", md: "auto" }}
          sx={{ pl: { sm: '23px', xs: '23px' }, pr: { xs: '23px' }, mt: { xs: 3 }, }}
          textAlign={{ xs: 'center' }}
        >
          <img class="qr" src={qr} alt={"qr"} />
        </Box>
      </Stack>


      <Stack
        direction={{ xs: "column-reverse", sm: "row", md: 'row' }}
        alignItems={{ md: "center", sm: 'center', xs: 'flex-start' }}
        sx={{
          mt: 8,
          width: {
            xs: "100%",
            md: "85%",
            sm: "100%"
          },
          pl: { md: '80px', xs: '23px', sm: '23px' },
          pr: { xs: '23px', sm: '23px' },
          display: { md: 'flex', sm: 'flex' },
          gap: "20px"
        }} >
        <Box
          sx={{
            mt: {
              xs: '20px',
            },
            width: { xs: "100%", sm: "50%", md: "60%" },
            height: { sm: "auto", md: "auto" },
          }}
        >
          <img src={circle} alt={"group"} class="groupImage" />
        </Box>

        <Box
          sx={{
            pl: { sm: '23px', xs: '23px', },
            pr: { xs: '23px', },
          }}
          width={{ xs: "100%", sm: "50%", md: "40%" }}
        >
          <Typography variant="h2"
            sx={{
              fontSize: { xs: "17px", sm: "27px", md: "39px" },
              fontWeight: "500",
              lineHeight: "3.175rem",
              color: "#222222",

            }}>
            Payment Link
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "1rem", md: "1rem", sm: '1rem' },
              color: "#696969",
              fontWeight: "bold",
            }}
          >
            WallX offers a simplified access to various financial and lifestyle services which we have summarized it as “Share, Spend with Ease.”
          </Typography>
          <Stack
            direction={'row'}
            gap={2}
            sx={{ mt: 4 }}
            className="storeIcon"
          >
            <Box>
              <AppleStoreButton dark />
            </Box>
            <Box>
              <PlayStoreButton />
            </Box>
          </Stack>
        </Box>
      </Stack>


      <Box>
        <Box marginX={{ xs: "23px", sm: "0px" }} sx={{ mt: 6, mb: 6, }}>
          <Lifestyle />
        </Box>
        <Box
          sx={{ mt: 4, display: { xs: "block", md: "flex" }, height: "450px" }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "initial" },
              width: "50%",
              backgroundImage: `url(${wrapper})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#4A4697",
              backgroundBlendMode: "multiply",
            }}
          >
            {/* <img height={400} style={{width: "100%"}} src={wrapper} alt="Wrapper" /> */}
          </Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "50%" },
              backgroundColor: "#4640B9",
              height: "inherit",
            }}
          >
            <Box sx={{ p: 4 }}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "1.5rem",
                  color: "#fff",
                  mb: 4,
                }}
              >
                Need help? Reach out here
              </Typography>
              <Box sx={{ width: "80%" }}>
                <Stack>
                  <Input
                    sx={{
                      mt: 2,
                      border: "1px solid #fff",
                      borderRadius: "5px",
                      color: "#fff",
                      p: 2,
                    }}
                    placeholder="Enter your full name"
                    type="text"
                  />
                  <Input
                    sx={{
                      mt: 2,
                      border: "1px solid #fff",
                      borderRadius: "5px",
                      color: "#fff",
                      p: 2,
                    }}
                    placeholder="Enter your full email"
                    type="email"
                  />
                  <Input
                    sx={{
                      mt: 2,
                      border: "1px solid #fff",
                      borderRadius: "5px",
                      color: "#fff",
                      p: 2,
                    }}
                    placeholder="Enter your phone number"
                    type="number"
                  />
                </Stack>
                <Button
                  sx={{
                    color: "#000",
                    textAlign: "end !important",
                    background: "#fff",
                    mt: 2,
                    pr: 5,
                    pl: 5,
                    borderRadius: "15px",
                  }}
                >
                  Send
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
