import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./layout/main";
import HomePage from "./pages/home";
import ServicePage from "./pages/service";
import AboutPage from "./pages/about";
import UnitPage from "./pages/unit";
import ContactPage from "./pages/contact";
import { ThemeProvider } from "@mui/system";
import Faqpage from "./pages/faq";
import Formpage from "./pages/form";
import CustomerStory from "./pages/customerstory";
import Pricing from "./pages/pricing";
import Team from "./pages/team";
import { createTheme } from "@mui/material/styles";
import AppTheme from "./style/theme/main";

import PricingPage from "./pages/pricing";
import ServicePosPage from "./pages/service/pos";
import MerchantPage from "./pages/service/merchant";
import LifestylePage from "./pages/service/lifestyle";

function App() {
  return (
    <ThemeProvider theme={createTheme(AppTheme)}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<HomePage />} />
            <Route path="service" element={<ServicePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="pricing" element={<PricingPage />} />
            <Route path="service/pos" element={<ServicePosPage />} />
            <Route path="service/business-suite" element={<MerchantPage />} />
            <Route path="service/lifestyle" element={<LifestylePage />} />
            <Route path="faq" element={<Faqpage />} />
            <Route path="customer" element={<CustomerStory />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="team" element={<Team />} />
          </Route>
          <Route path="/unit" element={<UnitPage />} />
          <Route path="/team/form" element={<Formpage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
