import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { FaCircle } from "react-icons/fa";
import CTABlock from "../component/ui/cta";
import LifestyleComponent from "../component/ui/lifestyle";
import { AppleStoreButton, PlayStoreButton } from "../component/ui/storeButton";
import "../style/css/home.css";
import AppTheme from "../style/theme/main";
import NavBar from "../component/layout/nav";

import img0 from "../images/home/hoverImg0.svg";
import img1 from "../images/home/hoverImg1.svg";
import img2 from "../images/home/hoverImg2.svg";
import img3 from "../images/home/hoverImg3.svg";

import phone1 from "../images/home/phone1.svg";
import phone2 from "../images/home/phone2.svg";
import phone from "../images/home/phone.svg";

import av1 from "../images/home/av1.svg";
import av2 from "../images/home/av2.svg";
import av3 from "../images/home/av3.svg";
import av4 from "../images/home/av4.svg";

import customerBg1Cover from "../images/home/customerBg1Cover.svg";
import customerBg1 from "../images/home/customerBg1.svg";
import customerBg0 from "../images/home/customerBg0.svg";

// import bg0 from "../images/home/bg1.svg"
import ted from "../images/home/ted.svg";
import txSection1 from "../images/home/tx_section1.svg";
import txSection0 from "../images/home/tx_section0.svg";

import "../style/css/home.css";
import { useRef } from "react";
import { BsCloudArrowDownFill } from "react-icons/bs";
import { useState } from "react";
import GetStartedButton from "../component/ui/getStartedButton";

const hoverImage = [
  { img: img0, text: "Share" },
  { img: img1, text: "With Ease" },
  { img: img2, text: "Spend" },
  { img: img3, text: "On WallX" },
];

const benefits = [
  {
    title: "Fast Payment",
    text: "Send money quickly and without delay whilst receiving notifications on every change.",
    url: av1,
  },
  {
    title: "Secured",
    text: "We offer consumers and business owners a financially secure haven where all their data is secure and cannot be altered.",
    url: av2,
  },
  {
    title: "Merchant Focus",
    text: "We provide merchants with a platform to conduct day-to-day business operations and quick access to prospective clients.",
    url: av3,
  },
  {
    title: "Transparancy",
    text: "WallX is a simplified technology that allows users, contributors, and businesses to view and watch every transaction made on the app without bias.",
    url: av4,
  },
];

export default function HomePage(props) {
  const customerRef = useRef(null);
  const dropDownRef = useRef(null);
  const [dropdown, setDropdown] = useState(false);

  return (
    <Box>
      {/* <Box sx={{ width: "100%", textAlign: "end", mt: -6 }}></Box> */}
      <Box
        // mt="-120px"
        pb={{ xs: "230px", sm: "205px", md: "21.41vw" }}
        position={"relative"}
        className="heroBg"
        color="white"
        px={{ xs: "23px", sm: "0px" }}
        pl={{ sm: "55px", md: "5.79vw" }}
        sx={{
          // background: {xs:`url(${bg0Mobile})`,sm:`url(${bg0})`},
          // backgroundSize: "cover,cover",
          backgroundRepeat: "no-repeat",
          // backgroundColor:"rgba(81, 75, 189, 0.98)"
          // background:
        }}
        // pt={{ xs: "70px", sm: "140px" }}
      >
        <Box pr={{ md: "2.89vw" }}>
          <NavBar dark />
        </Box>
        <Box
          mt={{ xs: "25px", sm: "40px", md: "4.22vw" }}
          maxWidth={{ md: "40.28vw" }}
        >
          <Typography
            letterSpacing="0"
            variant="h1"
            // fontSize={{ xs: "54px", md: "77px" }}
            lineHeight="125.5%"
          >
            Share, Spend, With <br /> Ease on WallX
          </Typography>
          <Typography
            mt={{ xs: "10px", sm: "17px", md: "1.74vw" }}
            fontWeight={"300"}
            width="100%"
            maxWidth={{ sm: "359px", md: "37.50vw" }}
            variant="h5"
          >
            Sign up for WallX to conduct social-lifestyle transactions that are
            suitable for both individuals and businesses.
          </Typography>

          <Stack
            // px={{ xs: "16px", sm: "99px", md: "140px" }}
            mt={{ xs: "16px", sm: "26px", md: "37px" }}
            direction={"row"}
            alignItems="center"
            flexWrap="wrap"
            rowGap={{ xs: "10px", sm: "16px", md: "23px" }}
            columnGap={{ xs: "10px", sm: "16px", md: "23px" }}
          >
            {/* <Box>
              <AppleStoreButton />
            </Box>
            <Box>
              <PlayStoreButton light />
            </Box> */}
            <Box>
              <Button
                onClick={() => setDropdown(true)}
                sx={{
                  color: "white",
                  minWidth: { xs: "61px", sm: "98px", md: "10.19vw" },
                }}
                ref={dropDownRef}
                startIcon={<BsCloudArrowDownFill />}
              >
                <Typography variant="body2">Download App</Typography>
              </Button>
              <Popover
                open={dropdown}
                anchorEl={dropDownRef.current}
                sx={{ marginTop: 1 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: { backgroundColor: "transparent" },
                  elevation: 0,
                }}
                onClose={() => setDropdown(false)}
              >
                <ClickAwayListener onClickAway={() => setDropdown(false)}>
                  <Stack rowGap={1}>
                    <PlayStoreButton light />
                    <AppleStoreButton />
                  </Stack>
                </ClickAwayListener>
              </Popover>
            </Box>
            <Box paddingY={"0px"}>
              <GetStartedButton
                href="https://business.wallx.co/register"
                target="_blank"
                sx={{ minWidth: { md: "17.36vw" } }}
              />
              {/* <Button
                variant="outlined"
                disableElevation
                sx={{
                  justifyContent: "flex-start",
                  minWidth: {xs:"104px", sm:"166px", md:"17.36vw"},
                  borderRadius: {xs:"23px", md:"1.74vw"},
                  color: "black",
                  height: { xs: "auto", sm: "37px", md: "4.63vw" },
                  backgroundColor: "white",
                  textAlign: "left",
                  textTransform: "capitalize",
                  ":hover": {
                    color: "black",
                    borderColor: "white",
                    backgroundColor: "white",
                  },
                }}
              >
                <Typography variant="body1" color="primary">Generate Payment Link</Typography>
              </Button> */}
            </Box>
          </Stack>
        </Box>
        <Box
          className="phoneHolder"
          position={"absolute"}
          display={{ xs: "none", md: "block" }}
          top={{ xs: "133px", sm: "133px", md: "16.32vw" }}
          width="100%"
          maxWidth={{ md: "20.25vw" }}
          right={{ xs: "113px", md: "18px", lg: "18.92vw" }}
        >
          <img
            alt=""
            width={"100%"}
            // style={{ height: "460px",maxHeight:"38.77vw" }}
            src={phone}
          />
        </Box>
      </Box>

      <Grid
        mt={{ xs: "28px", sm: "44px", md: "4.63vw" }}
        px={{ xs: "23px", sm: "48px", md: "4.98vw" }}
        mb={"35px"}
        container
        // spacing={{ xs: "34px", sm: "55px", md: "78px" }}
      >
        <Grid
          item={true}
          xs={12}
          textAlign="center"
          mb={{ xs: "10px", sm: "16px", md: "23px" }}
          // spacing={{ xs: "23px", sm: "38px", md: "54px" }}
        >
          <Box
            width="100%"
            maxWidth={{ xs: "378px", md: "35.30vw" }}
            marginX="auto"
          >
            <Typography
              variant="h3"
              fontSize={{ xs: "27px", sm: "28px", md: "2.89vw" }}
            >
              Why Choose WallX
            </Typography>
            <Typography
              fontWeight={"300"}
              fontSize={{ xs: "15px", md: "1.39vw" }}
              mt={{ xs: "14px", md: "1.04vw" }}
            >
              We offer a platform that enables you to meet the requirements of
              your business and customers.
            </Typography>
          </Box>
        </Grid>

        {benefits.map((item, id) => (
          <Grid
            justifyContent={"center"}
            textAlign={"center"}
            key={id+item}
            item={true}
            xs={12}
            sm={6}
            md={3}
          >
            <Stack justifyContent={"center"} direction="row">
              <Avatar
                sx={{
                  px: { xs: "19px", sm: "31px", md: "3.24vw" },
                  bgcolor: "#F6EFFA",
                  width: { xs: "73px", sm: "117px", md: "12.21vw" },
                  height: { xs: "73px", sm: "117px", md: "12.21vw" },
                }}
              >
                <img
                  src={item.url}
                  width="100%"
                  // height="78px"
                  alt={`${item.title}_image`}
                />
              </Avatar>
            </Stack>
            <Typography variant="h5" fontWeight={"500"} mt={"30px"} mb={"10px"}>
              {" "}
              {item.title}{" "}
            </Typography>
            <Typography
              textAlign={"left"}
              color={"#696969"}
              fontSize={{ xs: "8px", md: "0.93vw" }}
              mx="auto"
              maxWidth={{ xs: "282px", sm: "100%" }}
              fontWeight="300"
            >
              {item.text}
            </Typography>
          </Grid>
        ))}
      </Grid>

      <Stack bgcolor={"#FCF9FF"} mt={"142px"} position="relative">
        {/* reponsive background */}
        <Box
          position={"absolute"}
          display={{ xs: "block", sm: "none" }}
          top={"173px"}
          left="-22px"
        >
          <Avatar src={txSection0} sx={{ width: "114px", height: "114px" }} />
        </Box>

        <Box
          textAlign={"center"}
          mt={"53px"}
          marginX={"auto"}
          paddingX="23px"
          width="100%"
          maxWidth={{ xs: "372px", md: "33.68vw" }}
        >
          <Typography variant="h3" fontWeight="500" mb={"8px"}>
            Our Services{" "}
          </Typography>
          <Typography mt={"1px"}>
            Our Services are skewed to offer Seamless lifestyle for small
            business, merchants and Individuals....{" "}
          </Typography>
        </Box>

        <Grid
          container
          alignItems={"center"}
          justifyContent="space-between"
          mt={{ xs: "111px", sm: "-64px" }}
          pl={{ xs: "44px", sm: "71px", md: "100px" }}
          pr={{ xs: "23px", sm: "37px", md: "52px" }}
          spacing={{ xs: "28px", sm: "45px", md: "64px" }}
        >
          <Grid item xs={12} sm={6} md={4} display={{ xs: "none", sm: "flex" }}>
            <img
              width={"100%"}
              style={{ maxHeight: "518px" }}
              src={phone1}
              alt="payment_link_image"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} display={{ xs: "none", md: "flex" }}>
            <Divider sx={{ height: "15px", width: "100%" }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} mt="-30px">
            <Box
              display={{ xs: "none", sm: "block" }}
              textAlign={"right"}
              mb={"30px"}
              pr={"20px"}
            >
              <FaCircle
                color={AppTheme.palette.primary.main}
                style={{ width: "30px", height: "30px" }}
              />
            </Box>
            <Box mt="-7px" pl={{ xs: "85px", sm: "0" }}>
              <Typography variant="h4" letterSpacing={"1.5px"} fontWeight="500">
                {" "}
                Generate Payment Links{" "}
              </Typography>
              <Typography mt={"1px"}>
                Generate invoices to easily accept payments from your customers.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          position={"relative"}
          alignItems={"center"}
          sx={{ marginTop: { xs: "82px", sm: "-93px" }, paddingTop: "0px" }}
          pb={{ xs: "130px", sm: "56px", md: "79px" }}
          // spacing={{ xs: "28px", sm: "45px", md: "64px" }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            mt={{ xs: "35px", sm: "149px", md: "172px" }}
          >
            <Box
              pl={{ xs: "55px", sm: "88px", md: "125px" }}
              pr={{ xs: "42px", sm: "66px", md: "0" }}
            >
              <Typography variant="h4" letterSpacing={"1.5px"} fontWeight="500">
                {" "}
                PIN PAYMENT{" "}
              </Typography>
              <Typography mt={"1px"}>
                {" "}
                Generate a payment pin to perform the following type of
                transactions.
              </Typography>
              <List
                sx={{ mt: { xs: "10px", sm: "16px", md: "23px" } }}
                disablePadding
              >
                {[
                  "POS Cash Out",
                  "Merchant Payment",
                  "e-Voucher/Gift Card",
                ].map((item, id) => (
                  <ListItem disablePadding disableGutters key={item+id}>
                    <FaCircle
                      style={{ marginRight: "5px" }}
                      color={AppTheme.palette.primary.main}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: "300",
                        variant: "body1",
                        // fontSize: { xs: "12px", sm: "15px" },
                      }}
                      primary={item}
                    />
                  </ListItem>
                ))}
              </List>
              <Box mt={{ xs: "8px", sm: "16px", md: "25px" }}>
                <Button
                  variant="contained"
                  href="https://business.wallx.co/create-paycode/"
                  target="_blank"
                  sx={{
                    borderRadius: { xs: "10px", sm: "17px", md: "1.74vw" },
                    textTransform: "capitalize",
                    p: { xs: "7px", sm: "12px", md: "1.22vw" },
                  }}
                  disableElevation
                >
                  {" "}
                  <Typography fontSize={{ xs: "8px", sm: "9px", md: "0.93vw" }}>
                    Generate Pin Payment
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            display={{ xs: "none", md: "flex" }}
            px={{ xs: "28px", sm: "45px", md: "64px" }}
          >
            <Divider sx={{ height: "15px", width: "100%" }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} display={{ xs: "none", sm: "flex" }}>
            <img
              src={phone2}
              style={{ maxHeight: "500px" }}
              width={"100%"}
              alt="payment_link_image"
            />
          </Grid>
          <Box
            position={"absolute"}
            display={{ xs: "block", sm: "none" }}
            bottom={"52px"}
            right="0px"
            overflow={"hidden"}
          >
            <Avatar
              src={txSection1}
              sx={{ width: "114px", height: "114px", mr: "-22px" }}
            />
          </Box>
        </Grid>
      </Stack>

      <Box
        px={{ xs: "18px", sm: "0" }}
        mt={"110px"}
        // pl={{ xs: "18px", sm: "20px", md: "57px" }}
      >
        <LifestyleComponent />
      </Box>

      <Stack direction={{ xs: "column-reverse", sm: "row" }} my={"35px"} key={120}>
        <Box
          key={1}
          item
          width={{ xs: "100%", sm: "50%" }}
          mt={{ xs: "38px", sm: "0" }}
          minHeight={{ xs: "175px", sm: "280px" }}
          sx={{
            background: `url(${customerBg1Cover}),url(${customerBg1})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <video autoPlay poster={customerBg1} height="100%"  width="100%">
          <source src="https://res.cloudinary.com/eacademy/video/upload/v1643989706/Agency/Strategy.mp4"  type="video/mp4"/>
        </video> */}
        </Box>
        <Box
          key={2}
          width={{ xs: "100%", sm: "50%" }}
          // height={{xs:"175px", sm:"280px", md:"396px"}}
          textAlign="center"
          sx={{
            background: `url(${customerBg0})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            key={3}
            color="white"
            bgcolor={"rgba(17, 17, 17, 0.87)"}
            // height={{xs:"175px", sm:"280px", md:"396px"}}

            px={{ xs: "37px", sm: "59px", md: "84px" }}
            pb={{ xs: "34px", sm: "55px", md: "78px" }}
          >
            <Typography
              mb={{ xs: "26px", sm: "42px", md: "60px" }}
              pt={{ xs: "29px", sm: "46px", md: "66px" }}
              variant="h4"
              fontWeight={"800"}
            >
              Customer Stories
            </Typography>
            <Typography
              fontWeight={"600"}
              variant="h6"
              fontSize={{ xs: "12px", sm: "15px" }}
              my={"3px"}
            >
              We’ve been able to build something in <br /> 2 years that
              influences the life of over 30,000 people
            </Typography>
            <Typography
              mt={{ xs: "4px", sm: "7px", md: "10px" }}
              mb={{ xs: "11px", sm: "17px", md: "25px" }}
            >
              Don’t hear it from us, See what our users have to say
            </Typography>
            <Box>
              <img
                width={"100%"}
                style={{ maxWidth: "158px" }}
                alt=""
                src={ted}
              />
            </Box>
          </Box>
        </Box>
      </Stack>

      <Grid
        id="customer"
        ref={customerRef}
        container
        display={{ xs: "none", sm: "flex" }}
        mt={{ xs: "56px", sm: "89px", md: "126px" }}
        mb={{ xs: "54px", sm: "86px", md: "122px" }}
      >
        {hoverImage.map((imageItem, id) => (
          <Grid
            item
            xs={12}
            sm={6}
            width="50%"
            alignSelf="flex-end"
            position="relative"
            key={id}
          >
            <img
              style={{ marginBottom: "-5px" }}
              alt=""
              width={"100%"}
              src={imageItem.img}
            />
            <Stack
              position={"absolute"}
              top="0"
              sx={{
                ":hover": {
                  bgcolor: AppTheme.palette.primary.main,
                  color: "white",
                  opacity: "0.7",
                },
              }}
              color="rgba(0,0,0,0.0)"
              opacity="0"
              width="100%"
              height="calc(100% + 5px)"
              justifyContent={"center"}
              alignItems="center"
            >
              <Typography fontSize={"16px"}> {imageItem.text} </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Box id="cta" my={{ xs: "27px", sm: "43px", md: "61px" }}>
        <CTABlock />
      </Box>
    </Box>
  );
}
