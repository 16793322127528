// import {useEffect, useState} from "react";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material"
import { AppleStoreButton, PlayStoreButton } from "./storeButton"

import doublePhone from "../../images/general/ctaDoublePhone.svg"
import doublePhone2 from "../../images/general/ctaDoublePhone_2.svg"
import { useTheme } from "@emotion/react";

export default function CTABlock() {
  // const [mobile, setMobile] = useState(false)

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  // let width = window.screen.width
  // useEffect(() => {
  //   if(width <= 600){
  //     setMobile(true)
  //   }
  
  // },[width])
  
  // window.addEventListener('resize', function(){
  //   let width = window.screen.width
  //   if(width <= 600){
  //     setMobile(true)
  //   }else{
  //     setMobile(false)
  //   }
  // })
  return (
    <Box
      boxSizing={"border-box"}
      width="100%"
      sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
      bgcolor="#F4EAF8"
      px={{ xs: "35px", md: "70px" }}
      py={"35px"}
      marginX="auto"     
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: "35px", md: "70px" }}
        alignItems="center"
        justifyContent="center"
      >
      <Stack sx={{display:{xs:"block", md:"none"}}}>
      <Typography fontWeight={"600"} fontSize="1.5em">
            {" "}
            {/* Send And Receive Money Instantly */}
            Get Started with WallX Business Suite Today!
          </Typography>
          <Typography>
            {" "}
            WallX commerce allows you serve <br /> the needs of your business
            and customers.{" "}
          </Typography>
      </Stack>
        <Box>
          <img src={`${mobile ? doublePhone2 : doublePhone}`} alt="" />
        </Box>
        <Stack spacing="12px">
          <Typography sx={{display:{xs:"none", md:"block"}}} fontWeight={"600"} fontSize="1.5em">
            {" "}
            Get Started with WallX Business Suite Today!

          </Typography>
          <Typography sx={{display:{xs:"none", md:"block"}}}>
            {" "}
            WallX commerce allows you serve <br /> the needs of your business
            and customers.{" "}
          </Typography>
          <Stack direction={"row"} pt={"10px"} spacing={"20px"} flexWrap>
            <Box>
              <AppleStoreButton dark />
            </Box>
            <Box>
              <PlayStoreButton />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
