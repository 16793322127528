import React from "react";
import {
  Stack,
  Typography,
  Box,
  Button,
  IconButton,
} from "@mui/material";

import icon from "../images/faq/icon.png";
import img from "../images/faq/img.png";
import phoneimg from "../images/faq/phone.svg";
import NavBar from "../component/layout/nav";
import "./style/faq.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const notification = " You will be notified as soon as approved ";
const items = [
  {
    icons: icon,
    header: "What to Know About Wallx Merchant?",
    text: "Wallx for Merchants is a tool that allows businesses and people to easily combine professional operations with social/lifestyle transactions",
  },
  {
    icons: icon,
    header: "How to Sign up? ",
    text: "To sign up on Wallx.co you need to create a profile by uploading your BVN or NIN",
    steps: [
      {
        step: "Step 1: Enter all details requested on the form.",
      },
      {
        step: `Step 2: On successful sign-up, you will get a message saying, ${notification} . Approval takes max 48hrs. Please call us on 016304704 if your account stays inactivated."`,
      },
      {
        step: "Step 3: When activated, you will receive an email welcoming you to WallX.co and then you continue to Login with your email and password",
      },
    ],
  },
  {
    icons: icon,
    header: "What Can You Do on Wallx.co? ",
    text: "On WallX.co, you can:",
    lists: [
      "View your profile",
      "Check total sales, transactions and withdrawal",
      "Bitcoin balance",
      "Commission and earnings",
      "Customers' latest transaction",
      "Sales monthly and yearly history",
      "Top up your Wallx account (using USD or NGN)",
    ],
  },
  {
    icons: icon,
    header: "Can You Add a Supervisor as a Merchant on Wallx.co? ",
    text: "Yes, you can. You have the privilege of adding supervisors to oversee your business for you.",
  },
  {
    icons: icon,
    header: "How Do You Generate a Customer Walk-in Pay-slip? ",
    text: "To Generate a customer, walk-in pay slip you need to:",
    steps: [
      {
        step: "Step 1: Click on the customer record shown on the dashboard",
      },
      {
        step: "Step 2: Search for the customer's name or phone number",
      },
      {
        step: "Step 3: Click on the icon that shows generate walk-in customer pay-slip",
      },
      {
        step: "Step 4: Click on SELECTION TYPE and select either SMS or EMAIL",
      },
    ],
  },
  {
    icons: icon,
    header: "What Does the Customer Report Entail? ",
    text: "The customer report includes all the client's transactions. To obtain this report, search for the customer's name or phone number, and the details will be available for download",
  },
  {
    icons: icon,
    header:
      "Can You Send a General Message or Announcement to Your Employees Using Wallx.co? ",
    text: "Yes,you can",
    steps: [
      {
        step: "Step 1: Enter the subject(type in whatever you want to pass across)",
      },
      {
        step: "Step 2: Go to announcement type and select either SMS or Email means of communication",
      },
      {
        step: "Step 3: Search for the recipient names and send.",
      },
    ],
  },
  {
    icons: icon,
    header: "What's the Escrow Transaction All About? ",
    text: "This is a feature that enables you to obtain goods/products/services before making payment to the seller. With Escrow, your money is locked in the system and will only be released to the seller once all services have been completed completely.",
  },
  {
    icons: icon,
    header: "How to Make an Escrow Request? ",
    text: "To make an Escrow request",
    steps: [
      {
        step: "Step 1: Go to the dashboard",
      },
      {
        step: "Step 2: Search for the name of the person you did the Escrow transaction with,then you'll have a view of the amount,currency,transaction reference,and date",
      },
    ],
  },
  {
    icons: icon,
    header: "How to View or Download Sales Transactions on Wallx.co? ",
    text: "To download sales transactions on Wallx.co",
    steps: [
      {
        step: "Step 1: Click on the set date you,re downloading from",
      },
      {
        step: "Step 2: Click on All transactions made to your current date",
      },
      {
        step: "Step 3: Click on enter.",
      },
    ],
    texts:
      "After that, you may examine all transactions from the previous date to the present date if you like.",
  },
];
export default function Faqpage() {
  const navigate = useNavigate();
  const LIMIT = 5;
  const [page, setPage] = useState(1);

  const updatePage = (next = false) => {
    let tempPage = page;
    if (next) {
      tempPage = page * LIMIT;
      if (tempPage < items.length - 1) {
        setPage((val) => val + 1);
      }
    } else {
      if (page > 1) {
        setPage((val) => val - 1);
      }
    }
  };

  const startIndex = (page - 1) * LIMIT;
  const endIndex = page * LIMIT;

  return (
    <Box sx={{ fontFamily: "Monteserrat" }}>
      <Box
        pl={{ xs: "23px", sm: "55px", md: "5.79vw" }}
        pr={{ xs: "24px", md: "2.78vw" }}
      >
        <NavBar />
      </Box>
      <Box
        fontSize={{ xs: "24px", md: "1.74vw" }}
        my={{ xs: "24px", md: "1.74vw" }}
        px={{ xs: "23px", sm: "55px", md: "5.79vw" }}
      >
        <IconButton onClick={() => navigate(-1)} sx ={{
          fontSize:{ xs: "24px", md: "1.74vw" }  
        }}
        >
          <FaArrowLeft fontSize="inherit" color="#443792" />
        </IconButton>
      </Box>
      <Box
        sx={{
          px: { xs: "23px", sm: "55px", md: "5.79vw" },
          backgroundColor: "#FCF8FF",
        }}
        py={{ xs: "17px", sm: "27px", md: "2.78vw" }}
        mt={3}
        mb={3}
      >
        <Stack
          direction="row"
          sx={{}}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            width="100%"
            maxWidth={{ md: "36.46vw" }}
          >
            <Typography variant="h3" fontFamily={"500"} fontSize={{xs:"24px",sm:"28px",md:"2.89vw"}} sx={{ color: "#443792" }}>
              Ask us anything
            </Typography>
            {items.slice(startIndex, endIndex).map((item, index) => (
              <Box key={item.header} sx={{ marginTop: "30px" }}>
                <Stack
                  direction="row"
                  columnGap={{xs:"12px", sm:"15px", md:"1.56vw"}}
                  fontSize={{ xs: "11px", sm: "11px", md: "0.81vw" }}
                  alignItems="start"
                >
                  <Box width={"100%"} maxWidth={{ xs: "17px", md: "1.27vw" }}>
                    <img src={item.icons} alt="" width="100%" />
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "500", mt: "-5px" }}
                    >
                      {item.header}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: "400",
                        // fontSize: "12px",
                        // maxWidth: "400px",
                        mt: "5px",
                      }}
                    >
                      {item.text}
                    </Typography>
                    {item.steps?.length
                      ? item.steps.map((item) => (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                fontWeight: "400",
                                // fontSize: "12px",
                                mt: "15px",
                              }}
                            >
                              {" "}
                              {item.step}
                            </Typography>
                            <Typography variant="caption">
                              {item.texts}
                            </Typography>
                            {/* <Typography sx={{ fontWeight: '400', fontSize: '12px' }}> {item.content}</Typography> */}
                          </Stack>
                        ))
                      : ""}
                    {item.lists?.length
                      ? item.lists.map((item) => (
                          <ul>
                            <li
                              style={{
                                fontWeight: "400",
                                fontSize: "inherit",
                                marginLeft: "20px",
                              }}
                            >
                              <Typography fontSize={{xs:"11px",md:"0.81vw"}}> {item} </Typography>
                            </li>
                          </ul>
                        ))
                      : ""}
                  </Box>
                </Stack>
              </Box>
            ))}
          </Box>

          <Box
          display={{xs:"none",md:"block"}}
            width="100%"
            sx={{
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
            }}
            maxWidth={{ xs: "261px", sm: "417px", md: "43.52vw" }}
          >
            {/* <img
                src={img}
                alt=""
                width={"100%"}
                // style={{ width: "500px", left: "0", top: "0" }}
              /> */}
            <Box
              width="100%"
              mb={{ xs: "-40px", sm: "-63px", md: "-6.60vw" }}
              maxWidth={{ xs: "230px", sm: "366px", md: "38.25vw" }}
              style={{
                // position: "absolute",
                left: "0",
                top: "0",
              }}
            >
              <img src={phoneimg} alt="" width="100%" />
            </Box>
          </Box>
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          columnGap={2}
          rowGap={2}
          mt={3}
          p={5}
        >
          <Button
            onClick={() => updatePage()}
            variant="contained"
            sx={{
              background: "#443792",
              borderRadius: { xs: "8px", md: "0.58vw" },
              py:{xs:"7px",md:"0.49vw"},
              // height: { xs: "24px", md: "1.74vw" },
              width: {xs:"79px",md:"5.79vw"},
            }}
          >
            <Typography fontSize={{xs:"13px",md:"0.93vw"}}>Prev</Typography>
          </Button>
          <Button
            onClick={() => updatePage(true)}
            sx={{
              width: {xs:"79px",md:"5.79vw"},
              border: "0.5px solid #222222",
              // height: { xs: "24px", md: "1.74vw" },
              py:{xs:"7px",md:"0.49vw"},
              borderRadius: { xs: "8px", md: "0.58vw" },
              color: "black",
            }}
          >
            <Typography fontSize={{xs:"13px",md:"0.93vw"}}>Next</Typography>
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
