import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardActions, CardContent, Grid } from "@mui/material";
import desktopImg from "../images/nav/desktop-img.png";
import mobileImg from "../images/nav/deskTopImg.png";
import rectangle from "../images/nav/reactangle.png";
// import group from "../images/nav/group-pics.png"
import perk from "../images/nav/perk.png";
import arrowBtn from "../images/nav/arrowBtn.png";

import NavBar from "../component/layout/nav";
import { Link } from "react-router-dom";
import GetStartedButton from "../component/ui/getStartedButton";

export function Btn({
  bgcolor,
  color,
  padding,
  borderRadius,
  mt,
  value,
  border,
}) {
  return (
    <Link to="/team/form" style={{ textDecoration: "none" }}>
      <Box
        maxWidth={{ xs: "104px", sm: "166px", md: "17.36vw" }}
        height={{ xs: "28px", sm: "44px", md: "4.63vh" }}
      >
        <Typography
          bgcolor={bgcolor}
          color={color}
          padding={padding}
          borderRadius={borderRadius}
          mt={mt}
          border={border}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {value}
          <img
            style={{ marginLeft: "10px", height: "2.27vh" }}
            src={arrowBtn}
            alt="arrow btn"
          />
        </Typography>
      </Box>
    </Link>
  );
}

const positions = [
  {
    title: "Sales Specialist",
    text: "Join the team as a sales and business marketer and help bring the WallX brand to every corner of the globe.",
  },
  {
    title: "Digital Marketer",
    text: "Join our operations team to help power the backend of business and customer transactions.",
  },
  {
    title: "Product Manager",
    text: "Join us in creating amazing products that solve problems for businesses and customers.",
  },
];

export function MediaCard({ title, text }) {
  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: { sm: "348px", md: "25.64vw" },
        // minHeight: { xs: "105px", sm: "168px", md: "17.53vw" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: { xs: "30px", md: "2.20vw" },
        borderRadius: { xs: "8px", md: "0.58vw" },
        bgcolor: "#FCF8FF",
        boxShadow: {
          xs: "1px 10px 0px rgba(0, 0, 0, 0.25)",
          sm: "0.06vw 0.58vw 0px rgba(0, 0, 0, 0.25)",
        },
        // boxShadow: "0 10px 10px -5px gray",
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          fontWeight="500"
          fontSize={{xs:"24px",md:"1.74vw"}}
          component="div"
          textAlign={"center"}
        >
          {title}
        </Typography>
        <Typography
          mt={{ xs: "16px", md: "1.16vw" }}
          variant="body2"
          color="text.secondary"
          textAlign={"center"}
        >
          {text}
        </Typography>
      </CardContent>
      <CardActions mt={{ xs: "33px", md: "2.43vw" }}>
        <GetStartedButton
          sx={{
            // mt: { xs: "33px", md: "2.43vw" },
            minWidth: { xs: "165px", md: "12.15vw" },
            minHeight: { xs: "41px", md: "3.01vw" },
          }}
          variant="outlined"
          text="Get Started"
          to="/team/form"
        />
        {/* <Btn
          bgcolor={"#FFFFFF"}
          borderRadius={"20px"}
          color={"#443792"}
          padding={"8px 35px"}
          value={"Apply Now"}
          border={"1px solid #7E7ACD"}
        /> */}
      </CardActions>
    </Card>
  );
}

export default function Team() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  let bgImage = desktopImg;
  let perkBenefit = perk;
  if (windowSize?.width > 700) {
    bgImage = desktopImg;
    perkBenefit = perk;
  } else {
    bgImage = mobileImg;
    // perkBenefit = group
  }

  // console.log(windowSize, bgImage)

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    //This auto runs when there is no resize for current device width and height
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      <Box
        sx={{
          height: { xs: "498px", sm: "732px", md: "45.14vw" },
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor:
            "linear-gradient(117.64deg, rgba(74, 70, 151, 0.52) 52.62%, rgba(50, 43, 176, 0.1196) 81.43%)",
          backgroundBlendMode: "darken",
        }}
      >
        <Box
          pl={{ xs: "24px", sm: "55px", md: "5.79vw" }}
          pr={{ xs: "24px", sm: "28px", md: "2.89vw" }}
        >
          <NavBar dark />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // width: { xs: "100%", md: "50%" },
            m: "0 auto",
            mt: { xs: "65px", sm: "83px", md: "6.68vw" },
            pb:{xs:"94px",sm:"0"}
          }}
          px={{ xs: "24px", sm: "55px", md: "5.79vw" }}
        >
          <Typography
            variant="h1"
            color={"#FFFFFF"}
            fontSize={{ xs: "24px", sm: "39px", md: "4.05vw" }}
            fontWeight={"500"}
            textAlign="center"
          >
            Join Our Global Team
          </Typography>
          <Typography
            variant="body1"
            // p={2}
            mt={{ xs: "8px", md: "0.52vw" }}
            maxWidth={{xs:"226px", sm:"361px", md:"37.73vw"}}
            color={"#FFFFFF"}
            fontSize={{ xs: "12px", sm: "17px", md: "1.74vw" }}
            textAlign={"center"}
          >
            Become a member of the WallX team and take your career to the next
            level.
          </Typography>
          <Box mt={{ xs: "10px", sm: "17px", md: "1.74vw" }}>
            <GetStartedButton sx={{maxWidth:{ sm:"38.00vw",md:"17.36vw"}}} text="Apply Now" to="/team/form" />
          </Box>
          {/* <Link style={{ textDecoration: "none" }}>
            <Btn
              bgcolor={"#FFFFFF"}
              borderRadius={"20px"}
              color={"#443792"}
              padding={"15px 55px"}
              value={"Apply Now"}
              mt={2}
            />
          </Link> */}
        </Box>
      </Box>

      <Box p={2} margin={"0 auto"} width={{ xs: "100%", md: "90%" }}>
        <Typography
          variant="h3"
          fontSize={{xs:"24px",md:"2.31vw"}}
        >
          Open Positions
        </Typography>
        <Typography
          variant="div"
          fontSize={{ xs: "10px", sm: "11px", md: "1.16vw" }}
          mt={{xs:"8px",sm:"11px",md:"1.16vw"}}
          // mb={4}
        >
          Join our vibrant growing team!. Check <br />out the job roles and
          apply
        </Typography>
        <Grid
          columnSpacing={{ xs: "40px", md: "2.95vw" }}
          rowSpacing={{ xs: "40px", }}
          container
          sx={{
            mt: { xs: "16px", sm: "26px", md: "2.72vw" },
            justifyContent: { xs: "center" },
            // flexWrap: "wrap",
          }}
        >
          {positions.map((item) => (
            <Grid item xs={12} sm={6} md={4}>
              <MediaCard {...item} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={{ xs: "63px", md: "8.68vw" }}>
        <Box
          pb={{ xs: "87px", sm: "139px", md: "14.53vw" }}
          // pb="100px"
          sx={{
            minHeight: { xs: "251px", sm: "292px", md: "292px" },
            backgroundImage: `url(${rectangle})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundColor:
              "linear-gradient(117.64deg, rgba(74, 70, 151, 0.52) 52.62%, rgba(50, 43, 176, 0.1196) 81.43%)",
            backgroundBlendMode: "darken",
            pt: { xs: "34px", md: "3.36vw" },
            px: { xs: "30px", sm: "86px", md: "8.97vw" },
          }}
        >
          <Box
            display={{ xs: "block", md: "flex" }}
            gap={{ xs: "30px", sm: "47px", md: "4.92vw" }}
            // justifyContent={"space-evenly"}
          >
            <Typography
              variant="h3"
              color={"#FFFFFF"}
              // fontSize={{ xs: "14px", sm: "22px", md: "2.26vw" }}
              fontWeight={500}
            >
              Perks and Benefits
            </Typography>
            <Typography
              variant="h5"
              color={"#FFFFFF"}
              marginTop={{ xs: "20px", md: "10px" }}
              maxWidth={{ sm: "481px", md: "50.23vw" }}

              // fontSize={{ xs: "8px", sm: "13px", md: "1.33vw" }}
            >
              Join WallX today to take advantage of incredible services,
              rewards, and discounts as an account owner.
            </Typography>
          </Box>
        </Box>
        <Box
          mt={{ xs: "-63px", sm: "-100px", md: "-10.47vw" }}
          // mx={"auto"}
          mb={{ xs: "34px", sm: "54px", md: "5.67vw" }}
          px={{ xs: "32px", sm: "101px", md: "auto" }}
          mx={"auto"}
          sx={{
            width: { sm: "100%", md: "80%" },
          }}
        >
          <img src={perkBenefit} style={{ width: "100%" }} alt="group" />
        </Box>
      </Box>
    </Box>
  );
}
