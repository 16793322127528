import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NavBar from "../../component/layout/nav";
import {
  AppleStoreButton,
  PlayStoreButton,
} from "../../component/ui/storeButton";
import { FaRegDotCircle } from "react-icons/fa";

import { Slide, Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import heroBg from "../../images/services/lifystyle/herobg.svg";
import heroTextBg from "../../images/services/lifystyle/heroTextBg.svg";

import heroImage01 from "../../images/services/lifystyle/hero01.svg";
import heroImage02 from "../../images/services/lifystyle/hero03.svg";
import heroImage03 from "../../images/services/lifystyle/hero02.svg";
import heroImage04 from "../../images/services/lifystyle/hero04.svg";

import heroImage11 from "../../images/services/lifystyle/hero11.svg";
import heroImage12 from "../../images/services/lifystyle/hero12.svg";
import heroImage13 from "../../images/services/lifystyle/hero13.svg";
import heroImage14 from "../../images/services/lifystyle/hero14.svg";
import heroImage15 from "../../images/services/lifystyle/hero15.svg";

import safeFeautureBg from "../../images/services/lifystyle/safeFeature.png";
import africaFeautureBg from "../../images/services/lifystyle/featureAfricaBg.svg";

// import featureTextBgDesktop from "../../images/services/lifystyle/featureTextBg.png"
// import featureTextBgDesktop from "../../images/services/lifystyle/featureTextBg.svg";
// import featureTextBgMobile from "../../images/services/lifystyle/featureTextMobileBg.svg";
// import africaFeautureBg from "../../images/services/lifystyle/featureAfricaBg.png"
import merchantBgDesktop from "../../images/services/lifystyle/merchantBg.svg";
import merchantBgMobile from "../../images/services/lifystyle/merchantBgMobile.svg";
import merchantDesktop from "../../images/services/lifystyle/merchantDesktop.png";
import merchantMobile from "../../images/services/lifystyle/merchantMobile.png";
import walletMobile from "../../images/services/lifystyle/walletMobile.svg";
import walletDesktop from "../../images/services/lifystyle/walletDesktop.svg";
import SlideCard from "../../component/ui/services/lifestyle/slideCard.";

// import "../../style/css/service/pos.css";

const walletBenefits = [
  "Pay Merchant",
  "Withdraw Funds",
  "Top-Up Wallet",
  "Bank Transfer",
];

const heroContent = [
  { text: "Bill Payment" },
  { image: heroImage01 },
  { text: "Cardless Withdrawal" },
  { image: heroImage02 },
  { text: "Savings Wallet" },
  { image: heroImage03 },
  { text: "Monetary Contribution" },
  { image: heroImage04 },
  { text: "Merchant Place" },
];

const heroContent1 = [
  { image: heroImage11 },
  { text: "Virtual Debit Cards" },
  { image: heroImage12 },
  { text: "Bills Splitting" },
  { image: heroImage13 },
  { text: "Physical Debit Cards" },
  { image: heroImage14 },
  { text: "Events Ticketing" },
  { image: heroImage15 },
];

const featureSlides = [
  {
    title: "Safe & Secured",
    text: " Make easy, fast and secured payments to merchants for goods and services without having your bank details compromised by hackers.",
  },
  {
    title: "Bank Details",
    text: "No bank detail are required to make a “Paycode”payment. Issue out a paycode to anyone without using or requesting for bank details.",
  },
  {
    title: "Fast Payment",
    text: "Avoid POS terminal failures at a store. Generate a “Paycode” and apply it at a store without being delayed.",
  },
  {
    title: "Gifts For Employee",
    text: "Employers can generate a “Paycode” for their employees to spend at your store in place of cash.",
  }, 
  {
    title: "POS Cardless Withdrawal",
    text: "No bank detail are required to make a “Paycode”payment. Issue out a paycode to anyone without using or requesting for bank details.",
  },
];

export default function LifestylePage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        color="white"
        position={"relative"}
        sx={{
          background: {
            xs: theme.palette.primary.main,
            md: `url(${heroBg})`,
          },
          backgroundSize: { md: "cover" },
          backgroundRepeat: { md: "no-repeat" },
        }}
        minHeight={{ xs: "228px", sm: "532px", md: "55.56vw" }}
      >
        <Box
          pl={{ xs: "23px", md: "5.79vw" }}
          pr={{ xs: "17px", md: "2.78vw" }}
        >
          <NavBar dark />
        </Box>
        <Box>
          <Box
            // mx={{ xs: "auto", sm: "53px" }}
            // px={{xs:"35px", sm:"55px", md:"5.79vw"}}
            px={{ xs: "23px", sm: "0" }}
            // pb={{ xs: "34px", sm: "0" }}
            // pb={{xs:"17px", sm:"28px", md:"2.89vw"}}
            maxWidth={{ sm: "100%" }}
            mt={{ xs: "55px", sm: "3.18vw" }}
            pb={{ xs: "46px", sm: "0.00vw" }}
          >
            <Typography
              variant="h3"
              textAlign={{ xs: "left", sm: "center" }}
              // fontSize={{ xs: "23px", sm: "auto" }}
              fontSize={{ xs: "39px", sm: "30px", md: "2.89vw" }}
              // fontWeight={{ xs: "400", sm: "700" }}
              fontWeight={"400"}
            >
              Providing You with Quick and Simple Financial Transactions
            </Typography>
          </Box>
          <Box
            position="absolute"
            width="100%"
            px={{ xs: "35px", sm: "55px", md: "5.79vw" }}
            minHeight={{ xs: "257px", sm: "410px", md: "42.77vw" }}
            bottom={{ xs: "-35px", sm: "-95px", md: "-5.79vw" }}
            display={{ xs: "none", sm: "block" }}
            className="slide-container"
            // mt={{xs:"17px", sm:"27px", md:"39px"}}
          >
            <Zoom
              scale={1}
              arrows={false}
              duration={10000}
              pauseOnHover={false}
            >
              <Grid
                container
                columnSpacing={{ xs: "15px", sm: "24px", md: "2.55vw" }}
                rowSpacing={{ xs: "10px", sm: "17px", md: "1.74vw" }}
              >
                {heroContent.map((item, index) => (
                  <Grid item xs={4}>
                    <Stack
                      justifyContent={"center"}
                      sx={{
                        background: `url(${item.image || heroTextBg})`,
                        backgroundSize: "cover",
                      }}
                      boxShadow="0px 1px 2px rgba(0,0,0,0.1)"
                      alignItems="center"
                      // width={{ xs: "164px", sm: "262px", md: "371px" }}
                      // minHeight={{ xs: "77px", sm: "123px", md: "175px" }}
                      height={{ xs: "78px", sm: "125px", md: "13.08vw" }}
                    >
                      {item.text && (
                        <Typography variant="h5" color="primary">
                          {" "}
                          {item.text}{" "}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                ))}
              </Grid>

              <Grid
                container
                columnSpacing={{ xs: "15px", sm: "24px", md: "2.55vw" }}
                rowSpacing={{ xs: "10px", sm: "17px", md: "1.74vw" }}
              >
                {heroContent1.map((item, index) => (
                  <Grid item xs={4}>
                    <Stack
                      justifyContent={"center"}
                      sx={{
                        background: `url(${item.image || heroTextBg})`,
                        backgroundSize: "cover",
                      }}
                      boxShadow="0px 1px 1px rgba(0,0,0,0.1)"
                      alignItems="center"
                      // width={{ xs: "164px", sm: "262px", md: "371px" }}
                      height={{ xs: "78px", sm: "125px", md: "13.08vw" }}
                    >
                      {item.text && (
                        <Typography variant="h5" color="primary">
                          {" "}
                          {item.text}{" "}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Zoom>
          </Box>
        </Box>
      </Box>

      <Stack
        direction={{ xs: "column", md: "row" }}
        columnGap={{ xs: "60px", sm: "96px", md: "137px" }}
        // columnGap={{ xs: "60px", sm: "96px", md: "10px" }}
        // rowGap={"10px"}
        justifyContent="space-between"
        mt={{ xs: "104px", sm: "166px", md: "17.36vw" }}
        // mt={{ xs: "124px", md: "175px" }}
      >
        <Box
          sx={{
            background: { md: `url(${africaFeautureBg})` },
            backgroundSize: { md: "cover" },
          }}
          width="100%"
          maxWidth={{ md: "41.03vw" }}
          minHeight={{ xs: "198px", sm: "316px", md: "32.99vw" }}
          // minHeight={{ md: "440px" }}
          // pb={{ md: "246px" }}
          pt={{ xs: "17px", sm: "28px", md: "2.89vw" }}
          pl={{ xs: "35px", sm: "55px", md: "5.79vw" }}
        >
          <Typography variant="body2"> Why WallX PAYCODE</Typography>
          <Stack
            mt={{ xs: "-42px", sm: "-67px", md: "-6.94vw" }}
            flexDirection={"row"}
            alignItems="center"
          >
            <Typography noWrap variant="h4" fontWeight={"600"}>
              OUR NEW & HOT
            </Typography>
            <Stack
              position={"relative"}
              alignItems="center"
              justifyContent={"center"}
              pl={{ xs: "10px", md: "24px" }}
              color="white"
              ml={{ xs: "10px", sm: "0px" }}
            >
              <Typography
                display={"inline-flex"}
                flexDirection="column"
                pt={{ xs: "44px", sm: "70px", md: "7.35vw" }}
                px={{ xs: "2px", sm: "3px", md: "0.35vw" }}
                pb={{ xs: "36px", sm: "58px", md: "6.08vw" }}
                justifyContent="center"
                borderRadius={{ xs: "31px", sm: "50px", md: "5.21vw" }}
                mb={{ xs: "-9px", sm: "-14px" }}
                sx={{
                  background:
                    "linear-gradient(107.33deg, #443792 -1.88%, rgba(142, 81, 221, 0.93) 100.64%)",
                }}
                variant="h4"
                fontWeight={"600"}
              >
                FEATURE
              </Typography>
              <Box
                width="100%"
                mt={{ xs: "6px", sm: "10px", md: "1.04vw" }}
                bgcolor="rgba(248, 248, 248, 0.18)"
                borderRadius={"50%"}
                boxShadow={{
                  xs: "5px 24px 28px rgba(0, 0, 0, 0.44)",
                  md: "0.29vw 1.39vw 1.62vw rgba(0, 0, 0, 0.44)",
                }}
                height={{ xs: "9px", sm: "14px", md: "1.50vw" }}
              ></Box>
            </Stack>
          </Stack>
        </Box>

        <Box
          sx={{ background: `url(${safeFeautureBg})`, backgroundSize: "cover" }}
          pt={{ xs: "20px", sm: "32px", md: "3.36vw" }}
          pb={{ xs: "7px", sm: "11px", md: "1.16vw" }}
          pl={{ xs: "27px", sm: "43px", md: "4.46vw" }}
          pr={{ xs: "33px", sm: "53px", md: "5.50vw" }}
          width="100%"
          maxWidth={{ md: "48.78vw" }}
        >
          <Slide slidesToShow={1} arrows={false}>
            {featureSlides.map((item) => (
              <SlideCard key={item.title} {...item} />
            ))}
          </Slide>
        </Box>
      </Stack>

      <Stack
        mt={{ xs: "46px", sm: "74px", md: "7.70vw" }}
        direction={{ xs: "column-reverse", md: "row" }}
        alignItems={{ md: "center" }}
        justifyContent="space-between"
        columnGap={{ xs: "32px", sm: "52px", md: "5.38vw" }}
        rowGap={"64px"}
      >
        <Box width="100%" maxWidth={{ md: "52.20vw" }}>
          <img
            src={isMobile ? walletMobile : walletDesktop}
            width="100%"
            alt="wallet_image"
          />
        </Box>
        <Box
          px={{ xs: "23px", md: "0" }}
          mr={{ xs: "28px", sm: "45px", md: "4.69vw" }}
          maxWidth={{ md: "37.67vw" }}
        >
          <Typography variant="subtitle1" fontWeight="300">
            Savings Wallet
          </Typography>
          <Typography
            mt={{ xs: "11px", md: "0.58vw" }}
            variant="h5"
            sx={{ color: "#201E4B" }}
            fontWeight="500"
          >
            With your money on WallX Savings Wallet, you can accomplish a lot
            more. Save now and receive incredible rewards.
          </Typography>
          <Grid
            container
            pt={{ xs: "23px", md: "2.89vw" }}
            columnSpacing={{ xs: "27px", md: "2.84vw" }}
            rowSpacing={{ xs: "29px", md: "1.97vw" }}
          >
            {walletBenefits.map((item) => (
              <Grid item xs={12} sm={6}>
                <Stack
                  spacing={{ xs: "11px", md: "0.58vw" }}
                  fontSize={{ xs: "20px", md: "1.50vw" }}
                  alignItems={"center"}
                  direction="row"
                >
                  <FaRegDotCircle
                    fontSize={"inherit"}
                    color={theme.palette.primary.main}
                  />
                  <Typography
                    fontSize={{ xs: "19px", md: "1.74vw" }}
                    variant="h5"
                  >
                    {" "}
                    {item}{" "}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>

      <Stack
        sx={{
          background: {
            xs: `url(${merchantBgMobile})`,
            md: `url(${merchantBgDesktop})`,
          },
          backgroundSize: { xs: "cover", md: "cover" },
          backgroundRepeat: "no-repeat",
        }}
        // minHeight={{ xs: "468px", sm: "747px", md: "78.01vw" }}
        mt={{ xs: "52px", sm: "83px", md: "8.68vw" }}
        pt={{ xs: "61px", sm: "98px", md: "10.19vw" }}
        pb={{ xs: "40px", sm: "63px", md: "6.60vw" }}
        // pt={{ xs: "49px", md: "23px" }}
        pl={{ xs: "23px", sm: "92px", md: "9.61vw" }}
        // pr={{ xs: "47px", md: "77px" }}
        pr={{ xs: "47px", md: "3.47vw" }}
        direction={{ xs: "column", md: "row" }}
        // flexWrap="wrap"
        alignItems={{ xs: "flex-start", md: "center" }}
        columnGap={{ xs: "11px", sm: "18px", md: "1.91vw" }}
        justifyContent="space-between"
        rowGap={"76px"}
      >
        <Box width="100%" maxWidth={{ md: "47.63vw" }}>
          <Typography
            sx={{ color: "white" }}
            variant="subtitle1"
            fontWeight="300"
            fontSize={{ xs: "19px", md: "1.39vw" }}
          >
            Marchant Place
          </Typography>
          <Typography
            mt={{ xs: "8px", sm: "13px", md: "19px" }}
            variant="h4"
            sx={{ color: "white" }}
            fontWeight="500"
          >
            Make Fast, Easy and Secured Payments to WallX Discount Merchants.
          </Typography>
          <Stack
            mt={{ xs: "8px", sm: "13px", md: "1.39vw" }}
            flexWrap="wrap"
            direction="row"
            rowGap={{ xs: "10px", sm: "17px", md: "1.74vw" }}
            columnGap={{ xs: "10px", sm: "17px", md: "1.74vw" }}
          >
            <AppleStoreButton />
            <PlayStoreButton light />
          </Stack>
        </Box>
        <Box width="100%" maxWidth={{ md: "34.09vw" }}>
          <img
            src={isMobile ? merchantMobile : merchantDesktop}
            width="100%"
            alt="hero_phone"
          />
        </Box>
      </Stack>
    </Box>
  );
}
