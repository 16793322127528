import { Box, Stack, Typography } from "@mui/material";

export default function PosSlideCard(props) {
  return (
    <Box
      style={{  
        background:
          "linear-gradient(97.68deg, rgba(244, 234, 248, 0.85) -2.63%, rgba(241, 233, 253, 0) 98.68%)",
        boxShadow: "4px 4px 7px rgba(0, 0, 0, 0.25)",
      }}
      pt={{ xs: "18px", sm: "29px", md: "3.07vw" }}
      pl={{ xs: "10px", sm: "17px", md: "1.74vw" }}
      height="100%"
      // maxWidth={{ xs: "240px", sm: "384px", md: "400px" }}
    >
      <Box
        maxWidth={{ sm: "315px", md: "32.93vw" }}
        pr={{ xs: "36px", sm: "58px", md: "6.08vw" }}
        textAlign="justify"
      >
        <Typography fontweight="500" fontSize={{xs:"24px",md:"1.74vw"}} variant="h5">
          {" "}
          {props.title}{" "}  
        </Typography>
        <Typography mt={{ xs: "3px", sm: "4px", md: "0.46vw" }} variant="body1">
          {" "}
          {props.text}{" "}
        </Typography>
      </Box>
      <Stack direction="row" justifyContent={"flex-end"}>
        <Box
        mt={{xs:"-5px", sm:"-33px", md:"-3.47vw"}}
          width={"100%"}
          maxWidth={{ xs: "105px", sm: "167px", md: "16.48vw" }}
          textAlign={"right"}
        >
          <img src={props.image} alt={`${props.title}_image`} width="100%" />
        </Box>
      </Stack>
    </Box>
  );
}
