import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { images } from "../images/customer/index";
// import AppTheme from "../style/theme/main";
import "../style/css/customerstory.css";
import NavBar from "../component/layout/nav";
// import { FaChevronCircleRight } from "react-icons/fa";

import { useTheme } from "@emotion/react";
import GetStartedButton from "../component/ui/getStartedButton";

const testimonial = [
  {
    text: "Works perfectly. I wholeheartedly suggest it to everyone I will undoubtedly suggest Wallx to others.",
    name: "Lukeman Francis",
    image: images.testimonial5,
    footer: images.testimonialFooter,
  },
  {
    text: "An incredible tool that allows you to track your savings and offers a very competitive interest rate.",
    name: "Chioma Okafor",
    image: images.testimonial4,
    footer: images.testimonialFooter,
  },
  {
    text: "The best software with a simple setup process that allows me to control all financial operations.",
    name: "John Benson",
    image: images.testimonial3,
    footer: images.testimonialFooter,
  },
  {
    text: " You guys rocked on the fintech space. Thank you so much for doing a great job",
    name: "Patrick Cana",
    image: images.testimonial2,
    footer: images.testimonialFooter,
  },
  {
    text: "Excellent app, simple onboarding process, secure and safe.",
    name: "Jane Malik",
    image: images.testimonial1,
    footer: images.testimonialFooter,
  },
];

const slideImages = [
  { text: "Food Industry", image: images.foodIndustry },
  { text: "Ecommerce", image: images.ecommerce },
  { text: "Logistics", image: images.logistics },
  { text: "Financial Services", image: images.financialServices },
];

export default function CustomerStory() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Box
        minHeight={{ xs: "475px", sm: "512px", md: "53.41vw" }}
        pb="24px"
        className="top-img"
      >
        <Box
          pl={{ xs: "24px", sm: "55px", md: "5.79vw" }}
          pr={{ xs: "24px", sm: "28px", md: "2.89vw" }}
        >
          <NavBar dark />
        </Box>
        <Stack
          flexDirection={"row"}
          position="relative"
          pl={{ xs: "24px", sm: "55px", md: "5.79vw" }}
          pr={{ xs: "24px", md: 0 }}
          // sx={{

          //   flexDirection: { xs: "column", sm: "row", md: "row" },
          //   width: "100%",
          // }}
          // minWidth={{ md: "100vw" }}
        >
          <Box
            mt={{ xs: "64px", sm: "69px", md: "7.23vw" }}
            sx={{
              color: "white",
              // height: { md: "400px", xs: "fit-content" },
              maxWidth: { md: "41.90vw" },
              // padding: { md: "50px", xs: "0 50px" },
            }}
          >
            <Typography variant="h1" sx={{ marginBottom: "10px" }}>
              Businesses Get Paid Instantly With WallX
            </Typography>
            <Typography
              mt={{ xs: "10px", sm: "11px", md: "1.16vw" }}
              variant="h5"
              component="div"
              // sx={{ marginBottom: { md: "10px", xs: "0px" } }}
            >
              Use WallX to get an immediate commission,discounts, and
              reimbursement for every transaction.
            </Typography>

            <Box mt={{ xs: "24px", md: "1.74vw" }}>
              <GetStartedButton href="https://business.wallx.co/register" />
            </Box>
          </Box>
          <Box
            display={{ xs: "none", md: "inline-flex" }}
            position="absolute"
            maxWidth={{ xs: "324px", sm: "517px", md: "53.99vw" }}
            mt={{ xs: "27px", sm: "44px", md: "4.57vw" }}
            width="100%"
            right="0"

            // pt={{ xs: "36px", sm: "0" }}
          >
            <img
              id="network_image"
              width="100%"
              src={images.group}
              alt="group of people"
            />
          </Box>
        </Stack>
      </Box>

      <Box sx={{ mt: { xs: "79px", md: "5.79vw" } }}>
        <Typography
          variant={mobile ? "h2" : "h4"}
          maxWidth={{ xs: "266px", sm: "100%" }}
          fontWeight={"500"}
          mx={"auto"}
          sx={{
            textAlign: "center",
            marginBottom: { xs: "24px", sm: "29px", md: "3.07vw" },
          }}
        >
          We Help Businesses of all Sizes Develop More Quickly.
        </Typography>
        <Stack
          flexDirection={"row"}
          sx={{
            display: { md: "flex", xs: "block" },
            marginBottom: "40px",
            justifyContent: "space-between",
            height: { md: "fit-content", xs: "fit-content" },
          }}
        >
          {mobile ? (
            <Stack className="slide-container">
              <Slide arrows={false} slidesToShow={1}>
                {slideImages.map((slideImage, index) => (
                  <Box className="each-slide" key={index}>
                    <Box className="image_color" sx={{ height: "fit-content" }}>
                      <Typography
                        variant="p"
                        sx={{ width: "max-content", fontWeight: "900" }}
                        className="image_text"
                      >
                        {slideImage.text}
                      </Typography>
                      <img
                        className="image"
                        src={slideImage.image}
                        key={index}
                        alt="services"
                      />
                    </Box>
                  </Box>
                ))}
              </Slide>
            </Stack>
          ) : (
            <>
              {slideImages.map((item, index) => {
                return (
                  <>
                    {index % 2 === 0 ? (
                      <Stack
                        className="image_color"
                        sx={{
                          marginTop: "50px",
                          height: "fit-content",
                          width: "100%",
                          maxWidth: { xs: "142px", sm: "227px", md: "23.73vw" },
                        }}
                      >
                        <Typography
                          variant="p"
                          className="image_text"
                          sx={{
                            width: "max-content",
                            fontSize: { xs: "14px", sm: "22px", md: "2.31vw" },
                          }}
                        >
                          {item.text}
                        </Typography>
                        <img
                          className="image"
                          src={item.image}
                          key={index}
                          alt="services"
                        />
                      </Stack>
                    ) : (
                      <Stack
                        className="image_color"
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          maxWidth: { xs: "142px", sm: "227px", md: "23.73vw" },
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            width: "max-content",
                            fontSize: { xs: "14px", sm: "22px", md: "2.31vw" },
                          }}
                          className="image_text"
                        >
                          {item.text}
                        </Typography>
                        <img
                          className="image"
                          src={item.image}
                          key={index}
                          alt="services"
                        />
                      </Stack>
                    )}
                  </>
                );
              })}
            </>
          )}
        </Stack>
      </Box>

      <Stack
        rowGap={{ xs: "39px" }}
        mt={{ xs: "79px", sm: "64px", md: "6.66vw" }}
        flexDirection={{ xs: "column", md: "row" }}
        px={{ xs: "24px", sm: "55px", md: "5.79vw" }}
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Box
          width={"100%"}
          maxWidth={{ md: "33.74vw" }}
          sx={
            {
              // padding: { md: "50px", xs: "30px", sm: "25px 0px 25px 150px" },
            }
          }
        >
          <Typography
            variant={mobile ? "h2" : "h4"}
            fontWeight="500"
            sx={{ marginBottom: "10px" }}
          >
            Make anything you're selling scaleable using WallX.
          </Typography>
          <Typography
            variant="subtitle1"
            // component="div"
            sx={{ marginBottom: "10px" }}
          >
            When you use the WallX application, you can become a trusted brand
            and gain customers for your business daily.
          </Typography>

          <Box mt={{ xs: "10px", sm: "17px", md: "1.74vw" }}>
            <GetStartedButton
              sx={{ display: { xs: "none", md: "block" } }}
              text={"Read Case Study"}
              color="white"
              gradient
            />
          </Box>
        </Box>
        <Box width="100%" maxWidth={{ md: "46.64vw" }}>
          <img className="image_tags" src={images.zipScan} alt="scan code" />
        </Box>
      </Stack>

      <Stack
        rowGap={{ xs: "39px" }}
        mt={{ xs: "39px", md: "10.65vw" }}
        flexDirection={{ xs: "column-reverse", md: "row" }}
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Box
          pr={{ xs: "24px", sm: "55px", md: "5.79vw" }}
          width="100%"
          maxWidth={{ md: "48.84vw" }}
        >
          <img className="image_tags" src={images.merchant} alt="scan code" />
        </Box>
        <Box px={{ xs: "24px", sm: "55px", md: "5.79vw" }}>
          <Typography
            variant={mobile ? "h2" : "h4"}
            fontWeight="500"
            sx={{ marginBottom: "10px" }}
          >
            See how we assisted merchants in achieving quantifiable results.
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              marginBottom: "10px",
              maxWidth: { md: "35.13vw" },
            }}
          >
            We have provided a larger audience for vendors to scale their
            businesses, as well as a community of buyers and customers to whom
            services can be marketed
          </Typography>
          <Box mt={{ xs: "10px", sm: "17px", md: "1.74vw" }}>
            <GetStartedButton
              sx={{ display: { xs: "none", md: "block" } }}
              text={"Read Case Study"}
              color="white"
              gradient
            />
          </Box>
        </Box>
      </Stack>

      <Box mt={{ xs: "79px", md: "5.79vw" }}>
        <Typography
          variant={mobile ? "h2" : "h4"}
          fontSize={{ sm: " 22px", md: "2.31vw" }}
          fontWeight="500"
          sx={{ textAlign: "center" }}
        >
          What our Customers are Saying
        </Typography>
        <Stack mt={{ xs: "24px", md: "1.74vw" }} className="slide-container">
          <Slide arrows={false} slidesToShow={mobile ? 1 : 3}>
            {testimonial.map((slideImage, index) => (
              <Box
                mx={{ xs: "10.00vw", sm: "2.89vw" }}
                height="100%"
                bgcolor="#FCF8FF"
                minHeight={{ xs: "224px", md: "16.49vw" }}
                maxWidth={{ xs: "88.40vw", sm: "25.58vw" }}
              >
                {/* <Box> */}
                <Stack height="100%" justifyContent={"space-between"}>
                  <Box
                    px={{ xs: "20px", md: "1.50vw" }}
                    pt={{ xs: "35px", md: "2.55vw" }}
                    pb={{ xs: "19px", md: "1.39vw" }}
                  >
                    <Typography fontSize={{ xs: "0.75em", md: "1.04vw" }}>
                      {slideImage.text}
                    </Typography>

                    <Box
                      maxWidth={{ xs: "31px", md: "2.31vw" }}
                      mt={{ xs: "27px", md: "1.97vw" }}
                    >
                      <img
                        src={slideImage.image}
                        alt="userpicture"
                        width="100%"
                      />
                    </Box>
                    <Typography
                      fontSize={{ xs: "19px", md: "1.39vw" }}
                      mt={{ xs: "8px", md: "0.58vw" }}
                    >
                      {slideImage.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: { xs: "8px", md: "0.58vw" },
                      background:
                        " linear-gradient(107.47deg, rgba(79, 55, 146, 0.7298) -4.29%, rgba(124, 61, 206, 0.89) 121.97%)",
                    }}
                  ></Box>
                </Stack>
                {/* </Box> */}
              </Box>
            ))}
          </Slide>
        </Stack>
      </Box>

      <Stack
        className="bottom-lines"
        mt={{ xs: "84px", md: "0" }}
        alignItems={{ md: "center" }}
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={{ xs: "column", md: "row" }}
        // pb={{ xs: "69px", md: "2.49vw" }}
        mb={{ xs: "69px", md: "1.27vw" }}
      >
        <Box
          ml={{ xs: "24px", md: "10.65vw" }}
          pr={{ xs: "24px", md: "0" }}
          maxWidth={{ md: "36.28vw" }}
          // maxWidth={{xs:"218px", sm:"348px", md:"36.28vw"}}
        >
          <Typography
            variant={mobile ? "h2" : "h4"}
            fontWeight="500"
            sx={{ marginBottom: { xs: "8px", sm: "11px", md: "1.16vw" } }}
          >
            Generate Virtual and Payment Debit Cards
          </Typography>
          <Typography
            variant="body1"
            maxWidth={{ md: "30.96vw" }}
            component="div"
            sx={{ textAlign: "justify" }}
          >
            Generate and personalize a debit card for your everyday
            transactions. Pay a merchant or host your card for online payments.
            You can also order for a physical card delivered to your doorstep.
          </Typography>
        </Box>
        <Box
          width="100%"
          pr={{ xs: "10px", md: "2.89vw" }}
          mx="auto"
          mt={{ xs: "5px", md: "3.76vw" }}
          maxWidth={{ sm: "441px", md: "46.01vw" }}
        >
          <img
            className="image_tags"
            src={mobile ? images.cardMobile : images.card}
            alt="debit card"
          />
        </Box>
      </Stack>
    </Box>
  );
}

// <Box
// // className="each-slide"
// width="100%"
// height="100%"
// display={"inline-flex"}
// sx={{ maxWidth: { md: "25.58vw" } }}
// key={index}
// >
// <Card
//   key={index}
//   height="100%"
//   sx={{
//     width: "100%",
//     mx: "24px",
//     borderBottom: "12px solid #5240b9",
//   }}
// >
//   {/* <CardActionArea> */}
//   <CardContent
//     sx={{
//       px: { xs: "20px", md: "1.50vw" },
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "space-around",
//     }}
//   >
//     <Typography
//       mt={{ xs: "35px", md: "2.55vw" }}
//       variant="body2"
//       mb={{ xs: "27px", md: "1.97vw" }}
//       component="div"
//       sx={{ textAlign: "justify" }}
//       color="text.secondary"
//     >
//       {slideImage.text}
//     </Typography>
//     <CardMedia
//       sx={{ maxWidth: { xs: "31px", md: "2.31vw" } }}
//     >
//       <img
//         src={slideImage.image}
//         width="100%"
//         alt="profile"
//       />
//     </CardMedia>
//     <Typography
//       mt={{ xs: "8px", md: "0.58vw" }}
//       gutterBottom
//       variant="h5"
//       component="div"
//     >
//       {slideImage.name}
//     </Typography>
//   </CardContent>
//   {/* <CardMedia>
// <img width="100%" src={slideImage.footer} alt="footer" />
// </CardMedia> */}
//   {/* </CardActionArea> */}
// </Card>
// </Box>
