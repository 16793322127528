import black_box from "./black_box.png";
import sterling from "./sterling.png";
import flutterwave from "./flutterwave.png";
import monnify from "./monnify.png";
import wema from "./wema.png";
import providus from "./providus.png";
import picture1b from "./picture_1_b.png";
import picture2s from "./picture_2_s.png";
import picture2b from "./picture_2_b.png";
import picture3b from "./picture_3_b.png";
import picture3s from "./picture_3_s.png";
import picture4b from "./picture_4_b.png";
import picture5s from "./picture_5_s.png";
import bg from "./bg.png";
import logo from "./logo.png";
import paystack from "./paystack.jpeg";
import verifyme from "./verifyMe.jpeg";
import man1 from "./man1.png";
import man1b from "./man1b.png";
import woman1 from "./woman1.png";
import woman1b from "./woman1b.png";
import woman2 from "./woman2.png";
import woman2b from "./woman2b.png";
import comment from "./comment.png";

export const images = {
    black_box,
    sterling,
    bg,
    flutterwave,
    monnify,
    wema,
    providus,
    picture1b,
    picture2s,
    logo,
    man1,
    man1b,
    woman1,
    woman2,
    woman2b,
    comment,
    woman1b,
    verifyme,
    paystack,
    picture2b,
    picture3b,
    picture3s,
    picture4b,
    picture5s,
}